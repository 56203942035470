:root {
  --bs-primary: #E50914;
  --bs-primary-rgb: 229, 9, 20;
  --bs-primary-bg-subtle: rgb(249.8, 205.8, 208);
  --bs-primary-border-subtle: rgb(244.6, 156.6, 161);
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-primary-hover-bg: rgb(183.2, 7.2, 16);
  --bs-primary-hover-border: rgb(160.3, 6.3, 14);
  --bs-primary-active-bg: rgb(183.2, 7.2, 16);
  --bs-primary-active-border: rgb(160.3, 6.3, 14);
  --bs-primary-shade-90: rgb(252.4, 230.4, 231.5);
  --bs-primary-shade-80: rgb(249.8, 205.8, 208);
  --bs-primary-shade-60: rgb(244.6, 156.6, 161);
  --bs-primary-shade-40: rgb(239.4, 107.4, 114);
  --bs-primary-shade-20: rgb(234.2, 58.2, 67);
  --bs-primary-shade-10: rgb(231.6, 33.6, 43.5);
  --bs-primary-tint-90: rgb(22.9, 0.9, 2);
  --bs-primary-tint-80: rgb(45.8, 1.8, 4);
  --bs-primary-tint-60: rgb(91.6, 3.6, 8);
  --bs-primary-tint-40: rgb(137.4, 5.4, 12);
  --bs-primary-tint-20: rgb(183.2, 7.2, 16);
  --bs-primary-tint-10: rgb(206.1, 8.1, 18);
  --bs-secondary: #f44336;
  --bs-secondary-rgb: 244, 67, 54;
  --bs-secondary-bg-subtle: rgb(252.8, 217.4, 214.8);
  --bs-secondary-border-subtle: rgb(250.6, 179.8, 174.6);
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-secondary-hover-bg: rgb(195.2, 53.6, 43.2);
  --bs-secondary-hover-border: rgb(170.8, 46.9, 37.8);
  --bs-secondary-active-bg: rgb(195.2, 53.6, 43.2);
  --bs-secondary-active-border: rgb(170.8, 46.9, 37.8);
  --bs-secondary-shade-90: rgb(253.9, 236.2, 234.9);
  --bs-secondary-shade-80: rgb(252.8, 217.4, 214.8);
  --bs-secondary-shade-60: rgb(250.6, 179.8, 174.6);
  --bs-secondary-shade-40: rgb(248.4, 142.2, 134.4);
  --bs-secondary-shade-20: rgb(246.2, 104.6, 94.2);
  --bs-secondary-shade-10: rgb(245.1, 85.8, 74.1);
  --bs-secondary-tint-90: rgb(24.4, 6.7, 5.4);
  --bs-secondary-tint-80: rgb(48.8, 13.4, 10.8);
  --bs-secondary-tint-60: rgb(97.6, 26.8, 21.6);
  --bs-secondary-tint-40: rgb(146.4, 40.2, 32.4);
  --bs-secondary-tint-20: rgb(195.2, 53.6, 43.2);
  --bs-secondary-tint-10: rgb(219.6, 60.3, 48.6);
  --bs-success: #4caf50;
  --bs-success-rgb: 76, 175, 80;
  --bs-success-bg-subtle: rgb(219.2, 239, 220);
  --bs-success-border-subtle: rgb(183.4, 223, 185);
  --bs-link-color-rgb: var(--bs-success-rgb);
  --bs-success-hover-bg: rgb(60.8, 140, 64);
  --bs-success-hover-border: rgb(53.2, 122.5, 56);
  --bs-success-active-bg: rgb(60.8, 140, 64);
  --bs-success-active-border: rgb(53.2, 122.5, 56);
  --bs-success-shade-90: rgb(237.1, 247, 237.5);
  --bs-success-shade-80: rgb(219.2, 239, 220);
  --bs-success-shade-60: rgb(183.4, 223, 185);
  --bs-success-shade-40: rgb(147.6, 207, 150);
  --bs-success-shade-20: rgb(111.8, 191, 115);
  --bs-success-shade-10: rgb(93.9, 183, 97.5);
  --bs-success-tint-90: rgb(7.6, 17.5, 8);
  --bs-success-tint-80: rgb(15.2, 35, 16);
  --bs-success-tint-60: rgb(30.4, 70, 32);
  --bs-success-tint-40: rgb(45.6, 105, 48);
  --bs-success-tint-20: rgb(60.8, 140, 64);
  --bs-success-tint-10: rgb(68.4, 157.5, 72);
  --bs-warning: #FF9800;
  --bs-warning-rgb: 255, 152, 0;
  --bs-warning-bg-subtle: rgb(255, 234.4, 204);
  --bs-warning-border-subtle: rgb(255, 213.8, 153);
  --bs-link-color-rgb: var(--bs-warning-rgb);
  --bs-warning-hover-bg: rgb(204, 121.6, 0);
  --bs-warning-hover-border: rgb(178.5, 106.4, 0);
  --bs-warning-active-bg: rgb(204, 121.6, 0);
  --bs-warning-active-border: rgb(178.5, 106.4, 0);
  --bs-warning-shade-90: rgb(255, 244.7, 229.5);
  --bs-warning-shade-80: rgb(255, 234.4, 204);
  --bs-warning-shade-60: rgb(255, 213.8, 153);
  --bs-warning-shade-40: rgb(255, 193.2, 102);
  --bs-warning-shade-20: rgb(255, 172.6, 51);
  --bs-warning-shade-10: rgb(255, 162.3, 25.5);
  --bs-warning-tint-90: rgb(25.5, 15.2, 0);
  --bs-warning-tint-80: rgb(51, 30.4, 0);
  --bs-warning-tint-60: rgb(102, 60.8, 0);
  --bs-warning-tint-40: rgb(153, 91.2, 0);
  --bs-warning-tint-20: rgb(204, 121.6, 0);
  --bs-warning-tint-10: rgb(229.5, 136.8, 0);
  --bs-danger: #d71c1c;
  --bs-danger-rgb: 215, 28, 28;
  --bs-danger-bg-subtle: rgb(247, 209.6, 209.6);
  --bs-danger-border-subtle: rgb(239, 164.2, 164.2);
  --bs-link-color-rgb: var(--bs-danger-rgb);
  --bs-danger-hover-bg: rgb(172, 22.4, 22.4);
  --bs-danger-hover-border: rgb(150.5, 19.6, 19.6);
  --bs-danger-active-bg: rgb(172, 22.4, 22.4);
  --bs-danger-active-border: rgb(150.5, 19.6, 19.6);
  --bs-danger-shade-90: rgb(251, 232.3, 232.3);
  --bs-danger-shade-80: rgb(247, 209.6, 209.6);
  --bs-danger-shade-60: rgb(239, 164.2, 164.2);
  --bs-danger-shade-40: rgb(231, 118.8, 118.8);
  --bs-danger-shade-20: rgb(223, 73.4, 73.4);
  --bs-danger-shade-10: rgb(219, 50.7, 50.7);
  --bs-danger-tint-90: rgb(21.5, 2.8, 2.8);
  --bs-danger-tint-80: rgb(43, 5.6, 5.6);
  --bs-danger-tint-60: rgb(86, 11.2, 11.2);
  --bs-danger-tint-40: rgb(129, 16.8, 16.8);
  --bs-danger-tint-20: rgb(172, 22.4, 22.4);
  --bs-danger-tint-10: rgb(193.5, 25.2, 25.2);
  --bs-info: #2196f3;
  --bs-info-rgb: 33, 150, 243;
  --bs-info-bg-subtle: rgb(210.6, 234, 252.6);
  --bs-info-border-subtle: rgb(166.2, 213, 250.2);
  --bs-link-color-rgb: var(--bs-info-rgb);
  --bs-info-hover-bg: rgb(26.4, 120, 194.4);
  --bs-info-hover-border: rgb(23.1, 105, 170.1);
  --bs-info-active-bg: rgb(26.4, 120, 194.4);
  --bs-info-active-border: rgb(23.1, 105, 170.1);
  --bs-info-shade-90: rgb(232.8, 244.5, 253.8);
  --bs-info-shade-80: rgb(210.6, 234, 252.6);
  --bs-info-shade-60: rgb(166.2, 213, 250.2);
  --bs-info-shade-40: rgb(121.8, 192, 247.8);
  --bs-info-shade-20: rgb(77.4, 171, 245.4);
  --bs-info-shade-10: rgb(55.2, 160.5, 244.2);
  --bs-info-tint-90: rgb(3.3, 15, 24.3);
  --bs-info-tint-80: rgb(6.6, 30, 48.6);
  --bs-info-tint-60: rgb(13.2, 60, 97.2);
  --bs-info-tint-40: rgb(19.8, 90, 145.8);
  --bs-info-tint-20: rgb(26.4, 120, 194.4);
  --bs-info-tint-10: rgb(29.7, 135, 218.7);
  --bs-light: #dee2e6;
  --bs-light-rgb: 222, 226, 230;
  --bs-light-bg-subtle: rgb(248.4, 249.2, 250);
  --bs-light-border-subtle: rgb(241.8, 243.4, 245);
  --bs-link-color-rgb: var(--bs-light-rgb);
  --bs-light-hover-bg: rgb(177.6, 180.8, 184);
  --bs-light-hover-border: rgb(155.4, 158.2, 161);
  --bs-light-active-bg: rgb(177.6, 180.8, 184);
  --bs-light-active-border: rgb(155.4, 158.2, 161);
  --bs-light-shade-90: rgb(251.7, 252.1, 252.5);
  --bs-light-shade-80: rgb(248.4, 249.2, 250);
  --bs-light-shade-60: rgb(241.8, 243.4, 245);
  --bs-light-shade-40: rgb(235.2, 237.6, 240);
  --bs-light-shade-20: rgb(228.6, 231.8, 235);
  --bs-light-shade-10: rgb(225.3, 228.9, 232.5);
  --bs-light-tint-90: rgb(22.2, 22.6, 23);
  --bs-light-tint-80: rgb(44.4, 45.2, 46);
  --bs-light-tint-60: rgb(88.8, 90.4, 92);
  --bs-light-tint-40: rgb(133.2, 135.6, 138);
  --bs-light-tint-20: rgb(177.6, 180.8, 184);
  --bs-light-tint-10: rgb(199.8, 203.4, 207);
  --bs-dark: #22292E;
  --bs-dark-rgb: 34, 41, 46;
  --bs-dark-bg-subtle: rgb(210.8, 212.2, 213.2);
  --bs-dark-border-subtle: rgb(166.6, 169.4, 171.4);
  --bs-link-color-rgb: var(--bs-dark-rgb);
  --bs-dark-hover-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-hover-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-active-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-active-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-shade-90: rgb(232.9, 233.6, 234.1);
  --bs-dark-shade-80: rgb(210.8, 212.2, 213.2);
  --bs-dark-shade-60: rgb(166.6, 169.4, 171.4);
  --bs-dark-shade-40: rgb(122.4, 126.6, 129.6);
  --bs-dark-shade-20: rgb(78.2, 83.8, 87.8);
  --bs-dark-shade-10: rgb(56.1, 62.4, 66.9);
  --bs-dark-tint-90: rgb(3.4, 4.1, 4.6);
  --bs-dark-tint-80: rgb(6.8, 8.2, 9.2);
  --bs-dark-tint-60: rgb(13.6, 16.4, 18.4);
  --bs-dark-tint-40: rgb(20.4, 24.6, 27.6);
  --bs-dark-tint-20: rgb(27.2, 32.8, 36.8);
  --bs-dark-tint-10: rgb(30.6, 36.9, 41.4);
  --bs-gray: #dfdddd;
  --bs-gray-rgb: 223, 221, 221;
  --bs-gray-bg-subtle: rgb(248.6, 248.2, 248.2);
  --bs-gray-border-subtle: rgb(242.2, 241.4, 241.4);
  --bs-link-color-rgb: var(--bs-gray-rgb);
  --bs-gray-hover-bg: rgb(178.4, 176.8, 176.8);
  --bs-gray-hover-border: rgb(156.1, 154.7, 154.7);
  --bs-gray-active-bg: rgb(178.4, 176.8, 176.8);
  --bs-gray-active-border: rgb(156.1, 154.7, 154.7);
  --bs-gray-shade-90: rgb(251.8, 251.6, 251.6);
  --bs-gray-shade-80: rgb(248.6, 248.2, 248.2);
  --bs-gray-shade-60: rgb(242.2, 241.4, 241.4);
  --bs-gray-shade-40: rgb(235.8, 234.6, 234.6);
  --bs-gray-shade-20: rgb(229.4, 227.8, 227.8);
  --bs-gray-shade-10: rgb(226.2, 224.4, 224.4);
  --bs-gray-tint-90: rgb(22.3, 22.1, 22.1);
  --bs-gray-tint-80: rgb(44.6, 44.2, 44.2);
  --bs-gray-tint-60: rgb(89.2, 88.4, 88.4);
  --bs-gray-tint-40: rgb(133.8, 132.6, 132.6);
  --bs-gray-tint-20: rgb(178.4, 176.8, 176.8);
  --bs-gray-tint-10: rgb(200.7, 198.9, 198.9);
  --bs-gray-dark: #343a40;
  --bs-gray-dark-rgb: 52, 58, 64;
  --bs-gray-dark-bg-subtle: rgb(214.4, 215.6, 216.8);
  --bs-gray-dark-border-subtle: rgb(173.8, 176.2, 178.6);
  --bs-link-color-rgb: var(--bs-gray-dark-rgb);
  --bs-gray-dark-hover-bg: rgb(41.6, 46.4, 51.2);
  --bs-gray-dark-hover-border: rgb(36.4, 40.6, 44.8);
  --bs-gray-dark-active-bg: rgb(41.6, 46.4, 51.2);
  --bs-gray-dark-active-border: rgb(36.4, 40.6, 44.8);
  --bs-gray-dark-shade-90: rgb(234.7, 235.3, 235.9);
  --bs-gray-dark-shade-80: rgb(214.4, 215.6, 216.8);
  --bs-gray-dark-shade-60: rgb(173.8, 176.2, 178.6);
  --bs-gray-dark-shade-40: rgb(133.2, 136.8, 140.4);
  --bs-gray-dark-shade-20: rgb(92.6, 97.4, 102.2);
  --bs-gray-dark-shade-10: rgb(72.3, 77.7, 83.1);
  --bs-gray-dark-tint-90: rgb(5.2, 5.8, 6.4);
  --bs-gray-dark-tint-80: rgb(10.4, 11.6, 12.8);
  --bs-gray-dark-tint-60: rgb(20.8, 23.2, 25.6);
  --bs-gray-dark-tint-40: rgb(31.2, 34.8, 38.4);
  --bs-gray-dark-tint-20: rgb(41.6, 46.4, 51.2);
  --bs-gray-dark-tint-10: rgb(46.8, 52.2, 57.6);
  --bs-indigo: #009688;
  --bs-indigo-rgb: 0, 150, 136;
  --bs-indigo-bg-subtle: rgb(204, 234, 231.2);
  --bs-indigo-border-subtle: rgb(153, 213, 207.4);
  --bs-link-color-rgb: var(--bs-indigo-rgb);
  --bs-indigo-hover-bg: rgb(0, 120, 108.8);
  --bs-indigo-hover-border: rgb(0, 105, 95.2);
  --bs-indigo-active-bg: rgb(0, 120, 108.8);
  --bs-indigo-active-border: rgb(0, 105, 95.2);
  --bs-indigo-shade-90: rgb(229.5, 244.5, 243.1);
  --bs-indigo-shade-80: rgb(204, 234, 231.2);
  --bs-indigo-shade-60: rgb(153, 213, 207.4);
  --bs-indigo-shade-40: rgb(102, 192, 183.6);
  --bs-indigo-shade-20: rgb(51, 171, 159.8);
  --bs-indigo-shade-10: rgb(25.5, 160.5, 147.9);
  --bs-indigo-tint-90: rgb(0, 15, 13.6);
  --bs-indigo-tint-80: rgb(0, 30, 27.2);
  --bs-indigo-tint-60: rgb(0, 60, 54.4);
  --bs-indigo-tint-40: rgb(0, 90, 81.6);
  --bs-indigo-tint-20: rgb(0, 120, 108.8);
  --bs-indigo-tint-10: rgb(0, 135, 122.4);
}
:root[data-bs-theme=dark] {
  --bs-primary-text-emphasis: rgb(239.4, 107.4, 114);
  --bs-primary-bg-subtle: rgb(45.8, 1.8, 4);
}
:root[data-bs-theme=dark] {
  --bs-secondary-text-emphasis: rgb(248.4, 142.2, 134.4);
  --bs-secondary-bg-subtle: rgb(48.8, 13.4, 10.8);
}
:root[data-bs-theme=dark] {
  --bs-success-text-emphasis: rgb(147.6, 207, 150);
  --bs-success-bg-subtle: rgb(15.2, 35, 16);
}
:root[data-bs-theme=dark] {
  --bs-warning-text-emphasis: rgb(255, 193.2, 102);
  --bs-warning-bg-subtle: rgb(51, 30.4, 0);
}
:root[data-bs-theme=dark] {
  --bs-danger-text-emphasis: rgb(231, 118.8, 118.8);
  --bs-danger-bg-subtle: rgb(43, 5.6, 5.6);
}
:root[data-bs-theme=dark] {
  --bs-info-text-emphasis: rgb(121.8, 192, 247.8);
  --bs-info-bg-subtle: rgb(6.6, 30, 48.6);
}
:root[data-bs-theme=dark] {
  --bs-light-text-emphasis: rgb(235.2, 237.6, 240);
  --bs-light-bg-subtle: rgb(44.4, 45.2, 46);
}
:root[data-bs-theme=dark] {
  --bs-dark-text-emphasis: rgb(122.4, 126.6, 129.6);
  --bs-dark-bg-subtle: rgb(6.8, 8.2, 9.2);
}
:root[data-bs-theme=dark] {
  --bs-gray-text-emphasis: rgb(235.8, 234.6, 234.6);
  --bs-gray-bg-subtle: rgb(44.6, 44.2, 44.2);
}
:root[data-bs-theme=dark] {
  --bs-gray-dark-text-emphasis: rgb(133.2, 136.8, 140.4);
  --bs-gray-dark-bg-subtle: rgb(10.4, 11.6, 12.8);
}
:root[data-bs-theme=dark] {
  --bs-indigo-text-emphasis: rgb(102, 192, 183.6);
  --bs-indigo-bg-subtle: rgb(0, 30, 27.2);
}

[data-bs-theme-color=color-1] {
  --bs-primary: #00C3F9;
  --bs-primary-rgb: 0, 195, 249;
  --bs-primary-bg-subtle: rgb(204, 243, 253.8);
  --bs-primary-border-subtle: rgb(153, 231, 252.6);
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-primary-hover-bg: rgb(0, 156, 199.2);
  --bs-primary-hover-border: rgb(0, 136.5, 174.3);
  --bs-primary-active-bg: rgb(0, 156, 199.2);
  --bs-primary-active-border: rgb(0, 136.5, 174.3);
  --bs-primary-shade-90: rgb(229.5, 249, 254.4);
  --bs-primary-shade-80: rgb(204, 243, 253.8);
  --bs-primary-shade-60: rgb(153, 231, 252.6);
  --bs-primary-shade-40: rgb(102, 219, 251.4);
  --bs-primary-shade-20: rgb(51, 207, 250.2);
  --bs-primary-shade-10: rgb(25.5, 201, 249.6);
  --bs-primary-tint-90: rgb(0, 19.5, 24.9);
  --bs-primary-tint-80: rgb(0, 39, 49.8);
  --bs-primary-tint-60: rgb(0, 78, 99.6);
  --bs-primary-tint-40: rgb(0, 117, 149.4);
  --bs-primary-tint-20: rgb(0, 156, 199.2);
  --bs-primary-tint-10: rgb(0, 175.5, 224.1);
  --bs-secondary: #573BFF;
  --bs-secondary-rgb: 87, 59, 255;
  --bs-secondary-bg-subtle: rgb(221.4, 215.8, 255);
  --bs-secondary-border-subtle: rgb(187.8, 176.6, 255);
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-secondary-hover-bg: rgb(69.6, 47.2, 204);
  --bs-secondary-hover-border: rgb(60.9, 41.3, 178.5);
  --bs-secondary-active-bg: rgb(69.6, 47.2, 204);
  --bs-secondary-active-border: rgb(60.9, 41.3, 178.5);
  --bs-secondary-shade-90: rgb(238.2, 235.4, 255);
  --bs-secondary-shade-80: rgb(221.4, 215.8, 255);
  --bs-secondary-shade-60: rgb(187.8, 176.6, 255);
  --bs-secondary-shade-40: rgb(154.2, 137.4, 255);
  --bs-secondary-shade-20: rgb(120.6, 98.2, 255);
  --bs-secondary-shade-10: rgb(103.8, 78.6, 255);
  --bs-secondary-tint-90: rgb(8.7, 5.9, 25.5);
  --bs-secondary-tint-80: rgb(17.4, 11.8, 51);
  --bs-secondary-tint-60: rgb(34.8, 23.6, 102);
  --bs-secondary-tint-40: rgb(52.2, 35.4, 153);
  --bs-secondary-tint-20: rgb(69.6, 47.2, 204);
  --bs-secondary-tint-10: rgb(78.3, 53.1, 229.5);
  --bs-success: #0D953E;
  --bs-success-rgb: 13, 149, 62;
  --bs-success-bg-subtle: rgb(206.6, 233.8, 216.4);
  --bs-success-border-subtle: rgb(158.2, 212.6, 177.8);
  --bs-link-color-rgb: var(--bs-success-rgb);
  --bs-success-hover-bg: rgb(10.4, 119.2, 49.6);
  --bs-success-hover-border: rgb(9.1, 104.3, 43.4);
  --bs-success-active-bg: rgb(10.4, 119.2, 49.6);
  --bs-success-active-border: rgb(9.1, 104.3, 43.4);
  --bs-success-shade-90: rgb(230.8, 244.4, 235.7);
  --bs-success-shade-80: rgb(206.6, 233.8, 216.4);
  --bs-success-shade-60: rgb(158.2, 212.6, 177.8);
  --bs-success-shade-40: rgb(109.8, 191.4, 139.2);
  --bs-success-shade-20: rgb(61.4, 170.2, 100.6);
  --bs-success-shade-10: rgb(37.2, 159.6, 81.3);
  --bs-success-tint-90: rgb(1.3, 14.9, 6.2);
  --bs-success-tint-80: rgb(2.6, 29.8, 12.4);
  --bs-success-tint-60: rgb(5.2, 59.6, 24.8);
  --bs-success-tint-40: rgb(7.8, 89.4, 37.2);
  --bs-success-tint-20: rgb(10.4, 119.2, 49.6);
  --bs-success-tint-10: rgb(11.7, 134.1, 55.8);
  --bs-warning: #EC7D10;
  --bs-warning-rgb: 236, 125, 16;
  --bs-warning-bg-subtle: rgb(251.2, 229, 207.2);
  --bs-warning-border-subtle: rgb(247.4, 203, 159.4);
  --bs-link-color-rgb: var(--bs-warning-rgb);
  --bs-warning-hover-bg: rgb(188.8, 100, 12.8);
  --bs-warning-hover-border: rgb(165.2, 87.5, 11.2);
  --bs-warning-active-bg: rgb(188.8, 100, 12.8);
  --bs-warning-active-border: rgb(165.2, 87.5, 11.2);
  --bs-warning-shade-90: rgb(253.1, 242, 231.1);
  --bs-warning-shade-80: rgb(251.2, 229, 207.2);
  --bs-warning-shade-60: rgb(247.4, 203, 159.4);
  --bs-warning-shade-40: rgb(243.6, 177, 111.6);
  --bs-warning-shade-20: rgb(239.8, 151, 63.8);
  --bs-warning-shade-10: rgb(237.9, 138, 39.9);
  --bs-warning-tint-90: rgb(23.6, 12.5, 1.6);
  --bs-warning-tint-80: rgb(47.2, 25, 3.2);
  --bs-warning-tint-60: rgb(94.4, 50, 6.4);
  --bs-warning-tint-40: rgb(141.6, 75, 9.6);
  --bs-warning-tint-20: rgb(188.8, 100, 12.8);
  --bs-warning-tint-10: rgb(212.4, 112.5, 14.4);
  --bs-danger: #E30000;
  --bs-danger-rgb: 227, 0, 0;
  --bs-danger-bg-subtle: rgb(249.4, 204, 204);
  --bs-danger-border-subtle: rgb(243.8, 153, 153);
  --bs-link-color-rgb: var(--bs-danger-rgb);
  --bs-danger-hover-bg: rgb(181.6, 0, 0);
  --bs-danger-hover-border: rgb(158.9, 0, 0);
  --bs-danger-active-bg: rgb(181.6, 0, 0);
  --bs-danger-active-border: rgb(158.9, 0, 0);
  --bs-danger-shade-90: rgb(252.2, 229.5, 229.5);
  --bs-danger-shade-80: rgb(249.4, 204, 204);
  --bs-danger-shade-60: rgb(243.8, 153, 153);
  --bs-danger-shade-40: rgb(238.2, 102, 102);
  --bs-danger-shade-20: rgb(232.6, 51, 51);
  --bs-danger-shade-10: rgb(229.8, 25.5, 25.5);
  --bs-danger-tint-90: rgb(22.7, 0, 0);
  --bs-danger-tint-80: rgb(45.4, 0, 0);
  --bs-danger-tint-60: rgb(90.8, 0, 0);
  --bs-danger-tint-40: rgb(136.2, 0, 0);
  --bs-danger-tint-20: rgb(181.6, 0, 0);
  --bs-danger-tint-10: rgb(204.3, 0, 0);
  --bs-info: #07A2C7;
  --bs-info-rgb: 7, 162, 199;
  --bs-info-bg-subtle: rgb(205.4, 236.4, 243.8);
  --bs-info-border-subtle: rgb(155.8, 217.8, 232.6);
  --bs-link-color-rgb: var(--bs-info-rgb);
  --bs-info-hover-bg: rgb(5.6, 129.6, 159.2);
  --bs-info-hover-border: rgb(4.9, 113.4, 139.3);
  --bs-info-active-bg: rgb(5.6, 129.6, 159.2);
  --bs-info-active-border: rgb(4.9, 113.4, 139.3);
  --bs-info-shade-90: rgb(230.2, 245.7, 249.4);
  --bs-info-shade-80: rgb(205.4, 236.4, 243.8);
  --bs-info-shade-60: rgb(155.8, 217.8, 232.6);
  --bs-info-shade-40: rgb(106.2, 199.2, 221.4);
  --bs-info-shade-20: rgb(56.6, 180.6, 210.2);
  --bs-info-shade-10: rgb(31.8, 171.3, 204.6);
  --bs-info-tint-90: rgb(0.7, 16.2, 19.9);
  --bs-info-tint-80: rgb(1.4, 32.4, 39.8);
  --bs-info-tint-60: rgb(2.8, 64.8, 79.6);
  --bs-info-tint-40: rgb(4.2, 97.2, 119.4);
  --bs-info-tint-20: rgb(5.6, 129.6, 159.2);
  --bs-info-tint-10: rgb(6.3, 145.8, 179.1);
  --bs-light: #dee2e6;
  --bs-light-rgb: 222, 226, 230;
  --bs-light-bg-subtle: rgb(248.4, 249.2, 250);
  --bs-light-border-subtle: rgb(241.8, 243.4, 245);
  --bs-link-color-rgb: var(--bs-light-rgb);
  --bs-light-hover-bg: rgb(177.6, 180.8, 184);
  --bs-light-hover-border: rgb(155.4, 158.2, 161);
  --bs-light-active-bg: rgb(177.6, 180.8, 184);
  --bs-light-active-border: rgb(155.4, 158.2, 161);
  --bs-light-shade-90: rgb(251.7, 252.1, 252.5);
  --bs-light-shade-80: rgb(248.4, 249.2, 250);
  --bs-light-shade-60: rgb(241.8, 243.4, 245);
  --bs-light-shade-40: rgb(235.2, 237.6, 240);
  --bs-light-shade-20: rgb(228.6, 231.8, 235);
  --bs-light-shade-10: rgb(225.3, 228.9, 232.5);
  --bs-light-tint-90: rgb(22.2, 22.6, 23);
  --bs-light-tint-80: rgb(44.4, 45.2, 46);
  --bs-light-tint-60: rgb(88.8, 90.4, 92);
  --bs-light-tint-40: rgb(133.2, 135.6, 138);
  --bs-light-tint-20: rgb(177.6, 180.8, 184);
  --bs-light-tint-10: rgb(199.8, 203.4, 207);
  --bs-dark: #22292E;
  --bs-dark-rgb: 34, 41, 46;
  --bs-dark-bg-subtle: rgb(210.8, 212.2, 213.2);
  --bs-dark-border-subtle: rgb(166.6, 169.4, 171.4);
  --bs-link-color-rgb: var(--bs-dark-rgb);
  --bs-dark-hover-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-hover-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-active-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-active-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-shade-90: rgb(232.9, 233.6, 234.1);
  --bs-dark-shade-80: rgb(210.8, 212.2, 213.2);
  --bs-dark-shade-60: rgb(166.6, 169.4, 171.4);
  --bs-dark-shade-40: rgb(122.4, 126.6, 129.6);
  --bs-dark-shade-20: rgb(78.2, 83.8, 87.8);
  --bs-dark-shade-10: rgb(56.1, 62.4, 66.9);
  --bs-dark-tint-90: rgb(3.4, 4.1, 4.6);
  --bs-dark-tint-80: rgb(6.8, 8.2, 9.2);
  --bs-dark-tint-60: rgb(13.6, 16.4, 18.4);
  --bs-dark-tint-40: rgb(20.4, 24.6, 27.6);
  --bs-dark-tint-20: rgb(27.2, 32.8, 36.8);
  --bs-dark-tint-10: rgb(30.6, 36.9, 41.4);
  --bs-gray: #C2C2C2;
  --bs-gray-rgb: 194, 194, 194;
  --bs-gray-bg-subtle: rgb(242.8, 242.8, 242.8);
  --bs-gray-border-subtle: rgb(230.6, 230.6, 230.6);
  --bs-link-color-rgb: var(--bs-gray-rgb);
  --bs-gray-hover-bg: rgb(155.2, 155.2, 155.2);
  --bs-gray-hover-border: rgb(135.8, 135.8, 135.8);
  --bs-gray-active-bg: rgb(155.2, 155.2, 155.2);
  --bs-gray-active-border: rgb(135.8, 135.8, 135.8);
  --bs-gray-shade-90: rgb(248.9, 248.9, 248.9);
  --bs-gray-shade-80: rgb(242.8, 242.8, 242.8);
  --bs-gray-shade-60: rgb(230.6, 230.6, 230.6);
  --bs-gray-shade-40: rgb(218.4, 218.4, 218.4);
  --bs-gray-shade-20: rgb(206.2, 206.2, 206.2);
  --bs-gray-shade-10: rgb(200.1, 200.1, 200.1);
  --bs-gray-tint-90: rgb(19.4, 19.4, 19.4);
  --bs-gray-tint-80: rgb(38.8, 38.8, 38.8);
  --bs-gray-tint-60: rgb(77.6, 77.6, 77.6);
  --bs-gray-tint-40: rgb(116.4, 116.4, 116.4);
  --bs-gray-tint-20: rgb(155.2, 155.2, 155.2);
  --bs-gray-tint-10: rgb(174.6, 174.6, 174.6);
  --bs-gray-dark: #001F4D;
  --bs-gray-dark-rgb: 0, 31, 77;
  --bs-gray-dark-bg-subtle: rgb(204, 210.2, 219.4);
  --bs-gray-dark-border-subtle: rgb(153, 165.4, 183.8);
  --bs-link-color-rgb: var(--bs-gray-dark-rgb);
  --bs-gray-dark-hover-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-hover-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-active-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-active-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-shade-90: rgb(229.5, 232.6, 237.2);
  --bs-gray-dark-shade-80: rgb(204, 210.2, 219.4);
  --bs-gray-dark-shade-60: rgb(153, 165.4, 183.8);
  --bs-gray-dark-shade-40: rgb(102, 120.6, 148.2);
  --bs-gray-dark-shade-20: rgb(51, 75.8, 112.6);
  --bs-gray-dark-shade-10: rgb(25.5, 53.4, 94.8);
  --bs-gray-dark-tint-90: rgb(0, 3.1, 7.7);
  --bs-gray-dark-tint-80: rgb(0, 6.2, 15.4);
  --bs-gray-dark-tint-60: rgb(0, 12.4, 30.8);
  --bs-gray-dark-tint-40: rgb(0, 18.6, 46.2);
  --bs-gray-dark-tint-20: rgb(0, 24.8, 61.6);
  --bs-gray-dark-tint-10: rgb(0, 27.9, 69.3);
  --bs-indigo: #009688;
  --bs-indigo-rgb: 0, 150, 136;
  --bs-indigo-bg-subtle: rgb(204, 234, 231.2);
  --bs-indigo-border-subtle: rgb(153, 213, 207.4);
  --bs-link-color-rgb: var(--bs-indigo-rgb);
  --bs-indigo-hover-bg: rgb(0, 120, 108.8);
  --bs-indigo-hover-border: rgb(0, 105, 95.2);
  --bs-indigo-active-bg: rgb(0, 120, 108.8);
  --bs-indigo-active-border: rgb(0, 105, 95.2);
  --bs-indigo-shade-90: rgb(229.5, 244.5, 243.1);
  --bs-indigo-shade-80: rgb(204, 234, 231.2);
  --bs-indigo-shade-60: rgb(153, 213, 207.4);
  --bs-indigo-shade-40: rgb(102, 192, 183.6);
  --bs-indigo-shade-20: rgb(51, 171, 159.8);
  --bs-indigo-shade-10: rgb(25.5, 160.5, 147.9);
  --bs-indigo-tint-90: rgb(0, 15, 13.6);
  --bs-indigo-tint-80: rgb(0, 30, 27.2);
  --bs-indigo-tint-60: rgb(0, 60, 54.4);
  --bs-indigo-tint-40: rgb(0, 90, 81.6);
  --bs-indigo-tint-20: rgb(0, 120, 108.8);
  --bs-indigo-tint-10: rgb(0, 135, 122.4);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-primary-text-emphasis: rgb(102, 219, 251.4);
  --bs-primary-bg-subtle: rgb(0, 39, 49.8);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-secondary-text-emphasis: rgb(154.2, 137.4, 255);
  --bs-secondary-bg-subtle: rgb(17.4, 11.8, 51);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-success-text-emphasis: rgb(109.8, 191.4, 139.2);
  --bs-success-bg-subtle: rgb(2.6, 29.8, 12.4);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-warning-text-emphasis: rgb(243.6, 177, 111.6);
  --bs-warning-bg-subtle: rgb(47.2, 25, 3.2);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-danger-text-emphasis: rgb(238.2, 102, 102);
  --bs-danger-bg-subtle: rgb(45.4, 0, 0);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-info-text-emphasis: rgb(106.2, 199.2, 221.4);
  --bs-info-bg-subtle: rgb(1.4, 32.4, 39.8);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-light-text-emphasis: rgb(235.2, 237.6, 240);
  --bs-light-bg-subtle: rgb(44.4, 45.2, 46);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-dark-text-emphasis: rgb(122.4, 126.6, 129.6);
  --bs-dark-bg-subtle: rgb(6.8, 8.2, 9.2);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-gray-text-emphasis: rgb(218.4, 218.4, 218.4);
  --bs-gray-bg-subtle: rgb(38.8, 38.8, 38.8);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-gray-dark-text-emphasis: rgb(102, 120.6, 148.2);
  --bs-gray-dark-bg-subtle: rgb(0, 6.2, 15.4);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-indigo-text-emphasis: rgb(102, 192, 183.6);
  --bs-indigo-bg-subtle: rgb(0, 30, 27.2);
}

[data-bs-theme-color=color-2] {
  --bs-primary: #91969E;
  --bs-primary-rgb: 145, 150, 158;
  --bs-primary-bg-subtle: rgb(233, 234, 235.6);
  --bs-primary-border-subtle: rgb(211, 213, 216.2);
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-primary-hover-bg: rgb(116, 120, 126.4);
  --bs-primary-hover-border: rgb(101.5, 105, 110.6);
  --bs-primary-active-bg: rgb(116, 120, 126.4);
  --bs-primary-active-border: rgb(101.5, 105, 110.6);
  --bs-primary-shade-90: rgb(244, 244.5, 245.3);
  --bs-primary-shade-80: rgb(233, 234, 235.6);
  --bs-primary-shade-60: rgb(211, 213, 216.2);
  --bs-primary-shade-40: rgb(189, 192, 196.8);
  --bs-primary-shade-20: rgb(167, 171, 177.4);
  --bs-primary-shade-10: rgb(156, 160.5, 167.7);
  --bs-primary-tint-90: rgb(14.5, 15, 15.8);
  --bs-primary-tint-80: rgb(29, 30, 31.6);
  --bs-primary-tint-60: rgb(58, 60, 63.2);
  --bs-primary-tint-40: rgb(87, 90, 94.8);
  --bs-primary-tint-20: rgb(116, 120, 126.4);
  --bs-primary-tint-10: rgb(130.5, 135, 142.2);
  --bs-secondary: #FD8D00;
  --bs-secondary-rgb: 253, 141, 0;
  --bs-secondary-bg-subtle: rgb(254.6, 232.2, 204);
  --bs-secondary-border-subtle: rgb(254.2, 209.4, 153);
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-secondary-hover-bg: rgb(202.4, 112.8, 0);
  --bs-secondary-hover-border: rgb(177.1, 98.7, 0);
  --bs-secondary-active-bg: rgb(202.4, 112.8, 0);
  --bs-secondary-active-border: rgb(177.1, 98.7, 0);
  --bs-secondary-shade-90: rgb(254.8, 243.6, 229.5);
  --bs-secondary-shade-80: rgb(254.6, 232.2, 204);
  --bs-secondary-shade-60: rgb(254.2, 209.4, 153);
  --bs-secondary-shade-40: rgb(253.8, 186.6, 102);
  --bs-secondary-shade-20: rgb(253.4, 163.8, 51);
  --bs-secondary-shade-10: rgb(253.2, 152.4, 25.5);
  --bs-secondary-tint-90: rgb(25.3, 14.1, 0);
  --bs-secondary-tint-80: rgb(50.6, 28.2, 0);
  --bs-secondary-tint-60: rgb(101.2, 56.4, 0);
  --bs-secondary-tint-40: rgb(151.8, 84.6, 0);
  --bs-secondary-tint-20: rgb(202.4, 112.8, 0);
  --bs-secondary-tint-10: rgb(227.7, 126.9, 0);
  --bs-success: #18B55F;
  --bs-success-rgb: 24, 181, 95;
  --bs-success-bg-subtle: rgb(208.8, 240.2, 223);
  --bs-success-border-subtle: rgb(162.6, 225.4, 191);
  --bs-link-color-rgb: var(--bs-success-rgb);
  --bs-success-hover-bg: rgb(19.2, 144.8, 76);
  --bs-success-hover-border: rgb(16.8, 126.7, 66.5);
  --bs-success-active-bg: rgb(19.2, 144.8, 76);
  --bs-success-active-border: rgb(16.8, 126.7, 66.5);
  --bs-success-shade-90: rgb(231.9, 247.6, 239);
  --bs-success-shade-80: rgb(208.8, 240.2, 223);
  --bs-success-shade-60: rgb(162.6, 225.4, 191);
  --bs-success-shade-40: rgb(116.4, 210.6, 159);
  --bs-success-shade-20: rgb(70.2, 195.8, 127);
  --bs-success-shade-10: rgb(47.1, 188.4, 111);
  --bs-success-tint-90: rgb(2.4, 18.1, 9.5);
  --bs-success-tint-80: rgb(4.8, 36.2, 19);
  --bs-success-tint-60: rgb(9.6, 72.4, 38);
  --bs-success-tint-40: rgb(14.4, 108.6, 57);
  --bs-success-tint-20: rgb(19.2, 144.8, 76);
  --bs-success-tint-10: rgb(21.6, 162.9, 85.5);
  --bs-warning: #DB7817;
  --bs-warning-rgb: 219, 120, 23;
  --bs-warning-bg-subtle: rgb(247.8, 228, 208.6);
  --bs-warning-border-subtle: rgb(240.6, 201, 162.2);
  --bs-link-color-rgb: var(--bs-warning-rgb);
  --bs-warning-hover-bg: rgb(175.2, 96, 18.4);
  --bs-warning-hover-border: rgb(153.3, 84, 16.1);
  --bs-warning-active-bg: rgb(175.2, 96, 18.4);
  --bs-warning-active-border: rgb(153.3, 84, 16.1);
  --bs-warning-shade-90: rgb(251.4, 241.5, 231.8);
  --bs-warning-shade-80: rgb(247.8, 228, 208.6);
  --bs-warning-shade-60: rgb(240.6, 201, 162.2);
  --bs-warning-shade-40: rgb(233.4, 174, 115.8);
  --bs-warning-shade-20: rgb(226.2, 147, 69.4);
  --bs-warning-shade-10: rgb(222.6, 133.5, 46.2);
  --bs-warning-tint-90: rgb(21.9, 12, 2.3);
  --bs-warning-tint-80: rgb(43.8, 24, 4.6);
  --bs-warning-tint-60: rgb(87.6, 48, 9.2);
  --bs-warning-tint-40: rgb(131.4, 72, 13.8);
  --bs-warning-tint-20: rgb(175.2, 96, 18.4);
  --bs-warning-tint-10: rgb(197.1, 108, 20.7);
  --bs-danger: #F53030;
  --bs-danger-rgb: 245, 48, 48;
  --bs-danger-bg-subtle: rgb(253, 213.6, 213.6);
  --bs-danger-border-subtle: rgb(251, 172.2, 172.2);
  --bs-link-color-rgb: var(--bs-danger-rgb);
  --bs-danger-hover-bg: rgb(196, 38.4, 38.4);
  --bs-danger-hover-border: rgb(171.5, 33.6, 33.6);
  --bs-danger-active-bg: rgb(196, 38.4, 38.4);
  --bs-danger-active-border: rgb(171.5, 33.6, 33.6);
  --bs-danger-shade-90: rgb(254, 234.3, 234.3);
  --bs-danger-shade-80: rgb(253, 213.6, 213.6);
  --bs-danger-shade-60: rgb(251, 172.2, 172.2);
  --bs-danger-shade-40: rgb(249, 130.8, 130.8);
  --bs-danger-shade-20: rgb(247, 89.4, 89.4);
  --bs-danger-shade-10: rgb(246, 68.7, 68.7);
  --bs-danger-tint-90: rgb(24.5, 4.8, 4.8);
  --bs-danger-tint-80: rgb(49, 9.6, 9.6);
  --bs-danger-tint-60: rgb(98, 19.2, 19.2);
  --bs-danger-tint-40: rgb(147, 28.8, 28.8);
  --bs-danger-tint-20: rgb(196, 38.4, 38.4);
  --bs-danger-tint-10: rgb(220.5, 43.2, 43.2);
  --bs-info: #05B2DC;
  --bs-info-rgb: 5, 178, 220;
  --bs-info-bg-subtle: rgb(205, 239.6, 248);
  --bs-info-border-subtle: rgb(155, 224.2, 241);
  --bs-link-color-rgb: var(--bs-info-rgb);
  --bs-info-hover-bg: rgb(4, 142.4, 176);
  --bs-info-hover-border: rgb(3.5, 124.6, 154);
  --bs-info-active-bg: rgb(4, 142.4, 176);
  --bs-info-active-border: rgb(3.5, 124.6, 154);
  --bs-info-shade-90: rgb(230, 247.3, 251.5);
  --bs-info-shade-80: rgb(205, 239.6, 248);
  --bs-info-shade-60: rgb(155, 224.2, 241);
  --bs-info-shade-40: rgb(105, 208.8, 234);
  --bs-info-shade-20: rgb(55, 193.4, 227);
  --bs-info-shade-10: rgb(30, 185.7, 223.5);
  --bs-info-tint-90: rgb(0.5, 17.8, 22);
  --bs-info-tint-80: rgb(1, 35.6, 44);
  --bs-info-tint-60: rgb(2, 71.2, 88);
  --bs-info-tint-40: rgb(3, 106.8, 132);
  --bs-info-tint-20: rgb(4, 142.4, 176);
  --bs-info-tint-10: rgb(4.5, 160.2, 198);
  --bs-light: #dee2e6;
  --bs-light-rgb: 222, 226, 230;
  --bs-light-bg-subtle: rgb(248.4, 249.2, 250);
  --bs-light-border-subtle: rgb(241.8, 243.4, 245);
  --bs-link-color-rgb: var(--bs-light-rgb);
  --bs-light-hover-bg: rgb(177.6, 180.8, 184);
  --bs-light-hover-border: rgb(155.4, 158.2, 161);
  --bs-light-active-bg: rgb(177.6, 180.8, 184);
  --bs-light-active-border: rgb(155.4, 158.2, 161);
  --bs-light-shade-90: rgb(251.7, 252.1, 252.5);
  --bs-light-shade-80: rgb(248.4, 249.2, 250);
  --bs-light-shade-60: rgb(241.8, 243.4, 245);
  --bs-light-shade-40: rgb(235.2, 237.6, 240);
  --bs-light-shade-20: rgb(228.6, 231.8, 235);
  --bs-light-shade-10: rgb(225.3, 228.9, 232.5);
  --bs-light-tint-90: rgb(22.2, 22.6, 23);
  --bs-light-tint-80: rgb(44.4, 45.2, 46);
  --bs-light-tint-60: rgb(88.8, 90.4, 92);
  --bs-light-tint-40: rgb(133.2, 135.6, 138);
  --bs-light-tint-20: rgb(177.6, 180.8, 184);
  --bs-light-tint-10: rgb(199.8, 203.4, 207);
  --bs-dark: #22292E;
  --bs-dark-rgb: 34, 41, 46;
  --bs-dark-bg-subtle: rgb(210.8, 212.2, 213.2);
  --bs-dark-border-subtle: rgb(166.6, 169.4, 171.4);
  --bs-link-color-rgb: var(--bs-dark-rgb);
  --bs-dark-hover-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-hover-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-active-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-active-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-shade-90: rgb(232.9, 233.6, 234.1);
  --bs-dark-shade-80: rgb(210.8, 212.2, 213.2);
  --bs-dark-shade-60: rgb(166.6, 169.4, 171.4);
  --bs-dark-shade-40: rgb(122.4, 126.6, 129.6);
  --bs-dark-shade-20: rgb(78.2, 83.8, 87.8);
  --bs-dark-shade-10: rgb(56.1, 62.4, 66.9);
  --bs-dark-tint-90: rgb(3.4, 4.1, 4.6);
  --bs-dark-tint-80: rgb(6.8, 8.2, 9.2);
  --bs-dark-tint-60: rgb(13.6, 16.4, 18.4);
  --bs-dark-tint-40: rgb(20.4, 24.6, 27.6);
  --bs-dark-tint-20: rgb(27.2, 32.8, 36.8);
  --bs-dark-tint-10: rgb(30.6, 36.9, 41.4);
  --bs-gray: #BBBBBB;
  --bs-gray-rgb: 187, 187, 187;
  --bs-gray-bg-subtle: rgb(241.4, 241.4, 241.4);
  --bs-gray-border-subtle: rgb(227.8, 227.8, 227.8);
  --bs-link-color-rgb: var(--bs-gray-rgb);
  --bs-gray-hover-bg: rgb(149.6, 149.6, 149.6);
  --bs-gray-hover-border: rgb(130.9, 130.9, 130.9);
  --bs-gray-active-bg: rgb(149.6, 149.6, 149.6);
  --bs-gray-active-border: rgb(130.9, 130.9, 130.9);
  --bs-gray-shade-90: rgb(248.2, 248.2, 248.2);
  --bs-gray-shade-80: rgb(241.4, 241.4, 241.4);
  --bs-gray-shade-60: rgb(227.8, 227.8, 227.8);
  --bs-gray-shade-40: rgb(214.2, 214.2, 214.2);
  --bs-gray-shade-20: rgb(200.6, 200.6, 200.6);
  --bs-gray-shade-10: rgb(193.8, 193.8, 193.8);
  --bs-gray-tint-90: rgb(18.7, 18.7, 18.7);
  --bs-gray-tint-80: rgb(37.4, 37.4, 37.4);
  --bs-gray-tint-60: rgb(74.8, 74.8, 74.8);
  --bs-gray-tint-40: rgb(112.2, 112.2, 112.2);
  --bs-gray-tint-20: rgb(149.6, 149.6, 149.6);
  --bs-gray-tint-10: rgb(168.3, 168.3, 168.3);
  --bs-gray-dark: #001F4D;
  --bs-gray-dark-rgb: 0, 31, 77;
  --bs-gray-dark-bg-subtle: rgb(204, 210.2, 219.4);
  --bs-gray-dark-border-subtle: rgb(153, 165.4, 183.8);
  --bs-link-color-rgb: var(--bs-gray-dark-rgb);
  --bs-gray-dark-hover-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-hover-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-active-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-active-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-shade-90: rgb(229.5, 232.6, 237.2);
  --bs-gray-dark-shade-80: rgb(204, 210.2, 219.4);
  --bs-gray-dark-shade-60: rgb(153, 165.4, 183.8);
  --bs-gray-dark-shade-40: rgb(102, 120.6, 148.2);
  --bs-gray-dark-shade-20: rgb(51, 75.8, 112.6);
  --bs-gray-dark-shade-10: rgb(25.5, 53.4, 94.8);
  --bs-gray-dark-tint-90: rgb(0, 3.1, 7.7);
  --bs-gray-dark-tint-80: rgb(0, 6.2, 15.4);
  --bs-gray-dark-tint-60: rgb(0, 12.4, 30.8);
  --bs-gray-dark-tint-40: rgb(0, 18.6, 46.2);
  --bs-gray-dark-tint-20: rgb(0, 24.8, 61.6);
  --bs-gray-dark-tint-10: rgb(0, 27.9, 69.3);
  --bs-indigo: #009688;
  --bs-indigo-rgb: 0, 150, 136;
  --bs-indigo-bg-subtle: rgb(204, 234, 231.2);
  --bs-indigo-border-subtle: rgb(153, 213, 207.4);
  --bs-link-color-rgb: var(--bs-indigo-rgb);
  --bs-indigo-hover-bg: rgb(0, 120, 108.8);
  --bs-indigo-hover-border: rgb(0, 105, 95.2);
  --bs-indigo-active-bg: rgb(0, 120, 108.8);
  --bs-indigo-active-border: rgb(0, 105, 95.2);
  --bs-indigo-shade-90: rgb(229.5, 244.5, 243.1);
  --bs-indigo-shade-80: rgb(204, 234, 231.2);
  --bs-indigo-shade-60: rgb(153, 213, 207.4);
  --bs-indigo-shade-40: rgb(102, 192, 183.6);
  --bs-indigo-shade-20: rgb(51, 171, 159.8);
  --bs-indigo-shade-10: rgb(25.5, 160.5, 147.9);
  --bs-indigo-tint-90: rgb(0, 15, 13.6);
  --bs-indigo-tint-80: rgb(0, 30, 27.2);
  --bs-indigo-tint-60: rgb(0, 60, 54.4);
  --bs-indigo-tint-40: rgb(0, 90, 81.6);
  --bs-indigo-tint-20: rgb(0, 120, 108.8);
  --bs-indigo-tint-10: rgb(0, 135, 122.4);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-primary-text-emphasis: rgb(189, 192, 196.8);
  --bs-primary-bg-subtle: rgb(29, 30, 31.6);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-secondary-text-emphasis: rgb(253.8, 186.6, 102);
  --bs-secondary-bg-subtle: rgb(50.6, 28.2, 0);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-success-text-emphasis: rgb(116.4, 210.6, 159);
  --bs-success-bg-subtle: rgb(4.8, 36.2, 19);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-warning-text-emphasis: rgb(233.4, 174, 115.8);
  --bs-warning-bg-subtle: rgb(43.8, 24, 4.6);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-danger-text-emphasis: rgb(249, 130.8, 130.8);
  --bs-danger-bg-subtle: rgb(49, 9.6, 9.6);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-info-text-emphasis: rgb(105, 208.8, 234);
  --bs-info-bg-subtle: rgb(1, 35.6, 44);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-light-text-emphasis: rgb(235.2, 237.6, 240);
  --bs-light-bg-subtle: rgb(44.4, 45.2, 46);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-dark-text-emphasis: rgb(122.4, 126.6, 129.6);
  --bs-dark-bg-subtle: rgb(6.8, 8.2, 9.2);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-gray-text-emphasis: rgb(214.2, 214.2, 214.2);
  --bs-gray-bg-subtle: rgb(37.4, 37.4, 37.4);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-gray-dark-text-emphasis: rgb(102, 120.6, 148.2);
  --bs-gray-dark-bg-subtle: rgb(0, 6.2, 15.4);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-indigo-text-emphasis: rgb(102, 192, 183.6);
  --bs-indigo-bg-subtle: rgb(0, 30, 27.2);
}

[data-bs-theme-color=color-3] {
  --bs-primary: #DB5363;
  --bs-primary-rgb: 219, 83, 99;
  --bs-primary-bg-subtle: rgb(247.8, 220.6, 223.8);
  --bs-primary-border-subtle: rgb(240.6, 186.2, 192.6);
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-primary-hover-bg: rgb(175.2, 66.4, 79.2);
  --bs-primary-hover-border: rgb(153.3, 58.1, 69.3);
  --bs-primary-active-bg: rgb(175.2, 66.4, 79.2);
  --bs-primary-active-border: rgb(153.3, 58.1, 69.3);
  --bs-primary-shade-90: rgb(251.4, 237.8, 239.4);
  --bs-primary-shade-80: rgb(247.8, 220.6, 223.8);
  --bs-primary-shade-60: rgb(240.6, 186.2, 192.6);
  --bs-primary-shade-40: rgb(233.4, 151.8, 161.4);
  --bs-primary-shade-20: rgb(226.2, 117.4, 130.2);
  --bs-primary-shade-10: rgb(222.6, 100.2, 114.6);
  --bs-primary-tint-90: rgb(21.9, 8.3, 9.9);
  --bs-primary-tint-80: rgb(43.8, 16.6, 19.8);
  --bs-primary-tint-60: rgb(87.6, 33.2, 39.6);
  --bs-primary-tint-40: rgb(131.4, 49.8, 59.4);
  --bs-primary-tint-20: rgb(175.2, 66.4, 79.2);
  --bs-primary-tint-10: rgb(197.1, 74.7, 89.1);
  --bs-secondary: #366AF0;
  --bs-secondary-rgb: 54, 106, 240;
  --bs-secondary-bg-subtle: rgb(214.8, 225.2, 252);
  --bs-secondary-border-subtle: rgb(174.6, 195.4, 249);
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-secondary-hover-bg: rgb(43.2, 84.8, 192);
  --bs-secondary-hover-border: rgb(37.8, 74.2, 168);
  --bs-secondary-active-bg: rgb(43.2, 84.8, 192);
  --bs-secondary-active-border: rgb(37.8, 74.2, 168);
  --bs-secondary-shade-90: rgb(234.9, 240.1, 253.5);
  --bs-secondary-shade-80: rgb(214.8, 225.2, 252);
  --bs-secondary-shade-60: rgb(174.6, 195.4, 249);
  --bs-secondary-shade-40: rgb(134.4, 165.6, 246);
  --bs-secondary-shade-20: rgb(94.2, 135.8, 243);
  --bs-secondary-shade-10: rgb(74.1, 120.9, 241.5);
  --bs-secondary-tint-90: rgb(5.4, 10.6, 24);
  --bs-secondary-tint-80: rgb(10.8, 21.2, 48);
  --bs-secondary-tint-60: rgb(21.6, 42.4, 96);
  --bs-secondary-tint-40: rgb(32.4, 63.6, 144);
  --bs-secondary-tint-20: rgb(43.2, 84.8, 192);
  --bs-secondary-tint-10: rgb(48.6, 95.4, 216);
  --bs-success: #16DB65;
  --bs-success-rgb: 22, 219, 101;
  --bs-success-bg-subtle: rgb(208.4, 247.8, 224.2);
  --bs-success-border-subtle: rgb(161.8, 240.6, 193.4);
  --bs-link-color-rgb: var(--bs-success-rgb);
  --bs-success-hover-bg: rgb(17.6, 175.2, 80.8);
  --bs-success-hover-border: rgb(15.4, 153.3, 70.7);
  --bs-success-active-bg: rgb(17.6, 175.2, 80.8);
  --bs-success-active-border: rgb(15.4, 153.3, 70.7);
  --bs-success-shade-90: rgb(231.7, 251.4, 239.6);
  --bs-success-shade-80: rgb(208.4, 247.8, 224.2);
  --bs-success-shade-60: rgb(161.8, 240.6, 193.4);
  --bs-success-shade-40: rgb(115.2, 233.4, 162.6);
  --bs-success-shade-20: rgb(68.6, 226.2, 131.8);
  --bs-success-shade-10: rgb(45.3, 222.6, 116.4);
  --bs-success-tint-90: rgb(2.2, 21.9, 10.1);
  --bs-success-tint-80: rgb(4.4, 43.8, 20.2);
  --bs-success-tint-60: rgb(8.8, 87.6, 40.4);
  --bs-success-tint-40: rgb(13.2, 131.4, 60.6);
  --bs-success-tint-20: rgb(17.6, 175.2, 80.8);
  --bs-success-tint-10: rgb(19.8, 197.1, 90.9);
  --bs-warning: #F79256;
  --bs-warning-rgb: 247, 146, 86;
  --bs-warning-bg-subtle: rgb(253.4, 233.2, 221.2);
  --bs-warning-border-subtle: rgb(251.8, 211.4, 187.4);
  --bs-link-color-rgb: var(--bs-warning-rgb);
  --bs-warning-hover-bg: rgb(197.6, 116.8, 68.8);
  --bs-warning-hover-border: rgb(172.9, 102.2, 60.2);
  --bs-warning-active-bg: rgb(197.6, 116.8, 68.8);
  --bs-warning-active-border: rgb(172.9, 102.2, 60.2);
  --bs-warning-shade-90: rgb(254.2, 244.1, 238.1);
  --bs-warning-shade-80: rgb(253.4, 233.2, 221.2);
  --bs-warning-shade-60: rgb(251.8, 211.4, 187.4);
  --bs-warning-shade-40: rgb(250.2, 189.6, 153.6);
  --bs-warning-shade-20: rgb(248.6, 167.8, 119.8);
  --bs-warning-shade-10: rgb(247.8, 156.9, 102.9);
  --bs-warning-tint-90: rgb(24.7, 14.6, 8.6);
  --bs-warning-tint-80: rgb(49.4, 29.2, 17.2);
  --bs-warning-tint-60: rgb(98.8, 58.4, 34.4);
  --bs-warning-tint-40: rgb(148.2, 87.6, 51.6);
  --bs-warning-tint-20: rgb(197.6, 116.8, 68.8);
  --bs-warning-tint-10: rgb(222.3, 131.4, 77.4);
  --bs-danger: #F0544F;
  --bs-danger-rgb: 240, 84, 79;
  --bs-danger-bg-subtle: rgb(252, 220.8, 219.8);
  --bs-danger-border-subtle: rgb(249, 186.6, 184.6);
  --bs-link-color-rgb: var(--bs-danger-rgb);
  --bs-danger-hover-bg: rgb(192, 67.2, 63.2);
  --bs-danger-hover-border: rgb(168, 58.8, 55.3);
  --bs-danger-active-bg: rgb(192, 67.2, 63.2);
  --bs-danger-active-border: rgb(168, 58.8, 55.3);
  --bs-danger-shade-90: rgb(253.5, 237.9, 237.4);
  --bs-danger-shade-80: rgb(252, 220.8, 219.8);
  --bs-danger-shade-60: rgb(249, 186.6, 184.6);
  --bs-danger-shade-40: rgb(246, 152.4, 149.4);
  --bs-danger-shade-20: rgb(243, 118.2, 114.2);
  --bs-danger-shade-10: rgb(241.5, 101.1, 96.6);
  --bs-danger-tint-90: rgb(24, 8.4, 7.9);
  --bs-danger-tint-80: rgb(48, 16.8, 15.8);
  --bs-danger-tint-60: rgb(96, 33.6, 31.6);
  --bs-danger-tint-40: rgb(144, 50.4, 47.4);
  --bs-danger-tint-20: rgb(192, 67.2, 63.2);
  --bs-danger-tint-10: rgb(216, 75.6, 71.1);
  --bs-info: #2E93B9;
  --bs-info-rgb: 46, 147, 185;
  --bs-info-bg-subtle: rgb(213.2, 233.4, 241);
  --bs-info-border-subtle: rgb(171.4, 211.8, 227);
  --bs-link-color-rgb: var(--bs-info-rgb);
  --bs-info-hover-bg: rgb(36.8, 117.6, 148);
  --bs-info-hover-border: rgb(32.2, 102.9, 129.5);
  --bs-info-active-bg: rgb(36.8, 117.6, 148);
  --bs-info-active-border: rgb(32.2, 102.9, 129.5);
  --bs-info-shade-90: rgb(234.1, 244.2, 248);
  --bs-info-shade-80: rgb(213.2, 233.4, 241);
  --bs-info-shade-60: rgb(171.4, 211.8, 227);
  --bs-info-shade-40: rgb(129.6, 190.2, 213);
  --bs-info-shade-20: rgb(87.8, 168.6, 199);
  --bs-info-shade-10: rgb(66.9, 157.8, 192);
  --bs-info-tint-90: rgb(4.6, 14.7, 18.5);
  --bs-info-tint-80: rgb(9.2, 29.4, 37);
  --bs-info-tint-60: rgb(18.4, 58.8, 74);
  --bs-info-tint-40: rgb(27.6, 88.2, 111);
  --bs-info-tint-20: rgb(36.8, 117.6, 148);
  --bs-info-tint-10: rgb(41.4, 132.3, 166.5);
  --bs-light: #dee2e6;
  --bs-light-rgb: 222, 226, 230;
  --bs-light-bg-subtle: rgb(248.4, 249.2, 250);
  --bs-light-border-subtle: rgb(241.8, 243.4, 245);
  --bs-link-color-rgb: var(--bs-light-rgb);
  --bs-light-hover-bg: rgb(177.6, 180.8, 184);
  --bs-light-hover-border: rgb(155.4, 158.2, 161);
  --bs-light-active-bg: rgb(177.6, 180.8, 184);
  --bs-light-active-border: rgb(155.4, 158.2, 161);
  --bs-light-shade-90: rgb(251.7, 252.1, 252.5);
  --bs-light-shade-80: rgb(248.4, 249.2, 250);
  --bs-light-shade-60: rgb(241.8, 243.4, 245);
  --bs-light-shade-40: rgb(235.2, 237.6, 240);
  --bs-light-shade-20: rgb(228.6, 231.8, 235);
  --bs-light-shade-10: rgb(225.3, 228.9, 232.5);
  --bs-light-tint-90: rgb(22.2, 22.6, 23);
  --bs-light-tint-80: rgb(44.4, 45.2, 46);
  --bs-light-tint-60: rgb(88.8, 90.4, 92);
  --bs-light-tint-40: rgb(133.2, 135.6, 138);
  --bs-light-tint-20: rgb(177.6, 180.8, 184);
  --bs-light-tint-10: rgb(199.8, 203.4, 207);
  --bs-dark: #22292E;
  --bs-dark-rgb: 34, 41, 46;
  --bs-dark-bg-subtle: rgb(210.8, 212.2, 213.2);
  --bs-dark-border-subtle: rgb(166.6, 169.4, 171.4);
  --bs-link-color-rgb: var(--bs-dark-rgb);
  --bs-dark-hover-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-hover-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-active-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-active-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-shade-90: rgb(232.9, 233.6, 234.1);
  --bs-dark-shade-80: rgb(210.8, 212.2, 213.2);
  --bs-dark-shade-60: rgb(166.6, 169.4, 171.4);
  --bs-dark-shade-40: rgb(122.4, 126.6, 129.6);
  --bs-dark-shade-20: rgb(78.2, 83.8, 87.8);
  --bs-dark-shade-10: rgb(56.1, 62.4, 66.9);
  --bs-dark-tint-90: rgb(3.4, 4.1, 4.6);
  --bs-dark-tint-80: rgb(6.8, 8.2, 9.2);
  --bs-dark-tint-60: rgb(13.6, 16.4, 18.4);
  --bs-dark-tint-40: rgb(20.4, 24.6, 27.6);
  --bs-dark-tint-20: rgb(27.2, 32.8, 36.8);
  --bs-dark-tint-10: rgb(30.6, 36.9, 41.4);
  --bs-gray: #B3B3B3;
  --bs-gray-rgb: 179, 179, 179;
  --bs-gray-bg-subtle: rgb(239.8, 239.8, 239.8);
  --bs-gray-border-subtle: rgb(224.6, 224.6, 224.6);
  --bs-link-color-rgb: var(--bs-gray-rgb);
  --bs-gray-hover-bg: rgb(143.2, 143.2, 143.2);
  --bs-gray-hover-border: rgb(125.3, 125.3, 125.3);
  --bs-gray-active-bg: rgb(143.2, 143.2, 143.2);
  --bs-gray-active-border: rgb(125.3, 125.3, 125.3);
  --bs-gray-shade-90: rgb(247.4, 247.4, 247.4);
  --bs-gray-shade-80: rgb(239.8, 239.8, 239.8);
  --bs-gray-shade-60: rgb(224.6, 224.6, 224.6);
  --bs-gray-shade-40: rgb(209.4, 209.4, 209.4);
  --bs-gray-shade-20: rgb(194.2, 194.2, 194.2);
  --bs-gray-shade-10: rgb(186.6, 186.6, 186.6);
  --bs-gray-tint-90: rgb(17.9, 17.9, 17.9);
  --bs-gray-tint-80: rgb(35.8, 35.8, 35.8);
  --bs-gray-tint-60: rgb(71.6, 71.6, 71.6);
  --bs-gray-tint-40: rgb(107.4, 107.4, 107.4);
  --bs-gray-tint-20: rgb(143.2, 143.2, 143.2);
  --bs-gray-tint-10: rgb(161.1, 161.1, 161.1);
  --bs-gray-dark: #001F4D;
  --bs-gray-dark-rgb: 0, 31, 77;
  --bs-gray-dark-bg-subtle: rgb(204, 210.2, 219.4);
  --bs-gray-dark-border-subtle: rgb(153, 165.4, 183.8);
  --bs-link-color-rgb: var(--bs-gray-dark-rgb);
  --bs-gray-dark-hover-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-hover-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-active-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-active-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-shade-90: rgb(229.5, 232.6, 237.2);
  --bs-gray-dark-shade-80: rgb(204, 210.2, 219.4);
  --bs-gray-dark-shade-60: rgb(153, 165.4, 183.8);
  --bs-gray-dark-shade-40: rgb(102, 120.6, 148.2);
  --bs-gray-dark-shade-20: rgb(51, 75.8, 112.6);
  --bs-gray-dark-shade-10: rgb(25.5, 53.4, 94.8);
  --bs-gray-dark-tint-90: rgb(0, 3.1, 7.7);
  --bs-gray-dark-tint-80: rgb(0, 6.2, 15.4);
  --bs-gray-dark-tint-60: rgb(0, 12.4, 30.8);
  --bs-gray-dark-tint-40: rgb(0, 18.6, 46.2);
  --bs-gray-dark-tint-20: rgb(0, 24.8, 61.6);
  --bs-gray-dark-tint-10: rgb(0, 27.9, 69.3);
  --bs-indigo: #009688;
  --bs-indigo-rgb: 0, 150, 136;
  --bs-indigo-bg-subtle: rgb(204, 234, 231.2);
  --bs-indigo-border-subtle: rgb(153, 213, 207.4);
  --bs-link-color-rgb: var(--bs-indigo-rgb);
  --bs-indigo-hover-bg: rgb(0, 120, 108.8);
  --bs-indigo-hover-border: rgb(0, 105, 95.2);
  --bs-indigo-active-bg: rgb(0, 120, 108.8);
  --bs-indigo-active-border: rgb(0, 105, 95.2);
  --bs-indigo-shade-90: rgb(229.5, 244.5, 243.1);
  --bs-indigo-shade-80: rgb(204, 234, 231.2);
  --bs-indigo-shade-60: rgb(153, 213, 207.4);
  --bs-indigo-shade-40: rgb(102, 192, 183.6);
  --bs-indigo-shade-20: rgb(51, 171, 159.8);
  --bs-indigo-shade-10: rgb(25.5, 160.5, 147.9);
  --bs-indigo-tint-90: rgb(0, 15, 13.6);
  --bs-indigo-tint-80: rgb(0, 30, 27.2);
  --bs-indigo-tint-60: rgb(0, 60, 54.4);
  --bs-indigo-tint-40: rgb(0, 90, 81.6);
  --bs-indigo-tint-20: rgb(0, 120, 108.8);
  --bs-indigo-tint-10: rgb(0, 135, 122.4);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-primary-text-emphasis: rgb(233.4, 151.8, 161.4);
  --bs-primary-bg-subtle: rgb(43.8, 16.6, 19.8);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-secondary-text-emphasis: rgb(134.4, 165.6, 246);
  --bs-secondary-bg-subtle: rgb(10.8, 21.2, 48);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-success-text-emphasis: rgb(115.2, 233.4, 162.6);
  --bs-success-bg-subtle: rgb(4.4, 43.8, 20.2);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-warning-text-emphasis: rgb(250.2, 189.6, 153.6);
  --bs-warning-bg-subtle: rgb(49.4, 29.2, 17.2);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-danger-text-emphasis: rgb(246, 152.4, 149.4);
  --bs-danger-bg-subtle: rgb(48, 16.8, 15.8);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-info-text-emphasis: rgb(129.6, 190.2, 213);
  --bs-info-bg-subtle: rgb(9.2, 29.4, 37);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-light-text-emphasis: rgb(235.2, 237.6, 240);
  --bs-light-bg-subtle: rgb(44.4, 45.2, 46);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-dark-text-emphasis: rgb(122.4, 126.6, 129.6);
  --bs-dark-bg-subtle: rgb(6.8, 8.2, 9.2);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-gray-text-emphasis: rgb(209.4, 209.4, 209.4);
  --bs-gray-bg-subtle: rgb(35.8, 35.8, 35.8);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-gray-dark-text-emphasis: rgb(102, 120.6, 148.2);
  --bs-gray-dark-bg-subtle: rgb(0, 6.2, 15.4);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-indigo-text-emphasis: rgb(102, 192, 183.6);
  --bs-indigo-bg-subtle: rgb(0, 30, 27.2);
}

[data-bs-theme-color=color-4] {
  --bs-primary: #EA6A12;
  --bs-primary-rgb: 234, 106, 18;
  --bs-primary-bg-subtle: rgb(250.8, 225.2, 207.6);
  --bs-primary-border-subtle: rgb(246.6, 195.4, 160.2);
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-primary-hover-bg: rgb(187.2, 84.8, 14.4);
  --bs-primary-hover-border: rgb(163.8, 74.2, 12.6);
  --bs-primary-active-bg: rgb(187.2, 84.8, 14.4);
  --bs-primary-active-border: rgb(163.8, 74.2, 12.6);
  --bs-primary-shade-90: rgb(252.9, 240.1, 231.3);
  --bs-primary-shade-80: rgb(250.8, 225.2, 207.6);
  --bs-primary-shade-60: rgb(246.6, 195.4, 160.2);
  --bs-primary-shade-40: rgb(242.4, 165.6, 112.8);
  --bs-primary-shade-20: rgb(238.2, 135.8, 65.4);
  --bs-primary-shade-10: rgb(236.1, 120.9, 41.7);
  --bs-primary-tint-90: rgb(23.4, 10.6, 1.8);
  --bs-primary-tint-80: rgb(46.8, 21.2, 3.6);
  --bs-primary-tint-60: rgb(93.6, 42.4, 7.2);
  --bs-primary-tint-40: rgb(140.4, 63.6, 10.8);
  --bs-primary-tint-20: rgb(187.2, 84.8, 14.4);
  --bs-primary-tint-10: rgb(210.6, 95.4, 16.2);
  --bs-secondary: #6410F1;
  --bs-secondary-rgb: 100, 16, 241;
  --bs-secondary-bg-subtle: rgb(224, 207.2, 252.2);
  --bs-secondary-border-subtle: rgb(193, 159.4, 249.4);
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-secondary-hover-bg: rgb(80, 12.8, 192.8);
  --bs-secondary-hover-border: rgb(70, 11.2, 168.7);
  --bs-secondary-active-bg: rgb(80, 12.8, 192.8);
  --bs-secondary-active-border: rgb(70, 11.2, 168.7);
  --bs-secondary-shade-90: rgb(239.5, 231.1, 253.6);
  --bs-secondary-shade-80: rgb(224, 207.2, 252.2);
  --bs-secondary-shade-60: rgb(193, 159.4, 249.4);
  --bs-secondary-shade-40: rgb(162, 111.6, 246.6);
  --bs-secondary-shade-20: rgb(131, 63.8, 243.8);
  --bs-secondary-shade-10: rgb(115.5, 39.9, 242.4);
  --bs-secondary-tint-90: rgb(10, 1.6, 24.1);
  --bs-secondary-tint-80: rgb(20, 3.2, 48.2);
  --bs-secondary-tint-60: rgb(40, 6.4, 96.4);
  --bs-secondary-tint-40: rgb(60, 9.6, 144.6);
  --bs-secondary-tint-20: rgb(80, 12.8, 192.8);
  --bs-secondary-tint-10: rgb(90, 14.4, 216.9);
  --bs-success: #33C889;
  --bs-success-rgb: 51, 200, 137;
  --bs-success-bg-subtle: rgb(214.2, 244, 231.4);
  --bs-success-border-subtle: rgb(173.4, 233, 207.8);
  --bs-link-color-rgb: var(--bs-success-rgb);
  --bs-success-hover-bg: rgb(40.8, 160, 109.6);
  --bs-success-hover-border: rgb(35.7, 140, 95.9);
  --bs-success-active-bg: rgb(40.8, 160, 109.6);
  --bs-success-active-border: rgb(35.7, 140, 95.9);
  --bs-success-shade-90: rgb(234.6, 249.5, 243.2);
  --bs-success-shade-80: rgb(214.2, 244, 231.4);
  --bs-success-shade-60: rgb(173.4, 233, 207.8);
  --bs-success-shade-40: rgb(132.6, 222, 184.2);
  --bs-success-shade-20: rgb(91.8, 211, 160.6);
  --bs-success-shade-10: rgb(71.4, 205.5, 148.8);
  --bs-success-tint-90: rgb(5.1, 20, 13.7);
  --bs-success-tint-80: rgb(10.2, 40, 27.4);
  --bs-success-tint-60: rgb(20.4, 80, 54.8);
  --bs-success-tint-40: rgb(30.6, 120, 82.2);
  --bs-success-tint-20: rgb(40.8, 160, 109.6);
  --bs-success-tint-10: rgb(45.9, 180, 123.3);
  --bs-warning: #E76F51;
  --bs-warning-rgb: 231, 111, 81;
  --bs-warning-bg-subtle: rgb(250.2, 226.2, 220.2);
  --bs-warning-border-subtle: rgb(245.4, 197.4, 185.4);
  --bs-link-color-rgb: var(--bs-warning-rgb);
  --bs-warning-hover-bg: rgb(184.8, 88.8, 64.8);
  --bs-warning-hover-border: rgb(161.7, 77.7, 56.7);
  --bs-warning-active-bg: rgb(184.8, 88.8, 64.8);
  --bs-warning-active-border: rgb(161.7, 77.7, 56.7);
  --bs-warning-shade-90: rgb(252.6, 240.6, 237.6);
  --bs-warning-shade-80: rgb(250.2, 226.2, 220.2);
  --bs-warning-shade-60: rgb(245.4, 197.4, 185.4);
  --bs-warning-shade-40: rgb(240.6, 168.6, 150.6);
  --bs-warning-shade-20: rgb(235.8, 139.8, 115.8);
  --bs-warning-shade-10: rgb(233.4, 125.4, 98.4);
  --bs-warning-tint-90: rgb(23.1, 11.1, 8.1);
  --bs-warning-tint-80: rgb(46.2, 22.2, 16.2);
  --bs-warning-tint-60: rgb(92.4, 44.4, 32.4);
  --bs-warning-tint-40: rgb(138.6, 66.6, 48.6);
  --bs-warning-tint-20: rgb(184.8, 88.8, 64.8);
  --bs-warning-tint-10: rgb(207.9, 99.9, 72.9);
  --bs-danger: #FF5959;
  --bs-danger-rgb: 255, 89, 89;
  --bs-danger-bg-subtle: rgb(255, 221.8, 221.8);
  --bs-danger-border-subtle: rgb(255, 188.6, 188.6);
  --bs-link-color-rgb: var(--bs-danger-rgb);
  --bs-danger-hover-bg: rgb(204, 71.2, 71.2);
  --bs-danger-hover-border: rgb(178.5, 62.3, 62.3);
  --bs-danger-active-bg: rgb(204, 71.2, 71.2);
  --bs-danger-active-border: rgb(178.5, 62.3, 62.3);
  --bs-danger-shade-90: rgb(255, 238.4, 238.4);
  --bs-danger-shade-80: rgb(255, 221.8, 221.8);
  --bs-danger-shade-60: rgb(255, 188.6, 188.6);
  --bs-danger-shade-40: rgb(255, 155.4, 155.4);
  --bs-danger-shade-20: rgb(255, 122.2, 122.2);
  --bs-danger-shade-10: rgb(255, 105.6, 105.6);
  --bs-danger-tint-90: rgb(25.5, 8.9, 8.9);
  --bs-danger-tint-80: rgb(51, 17.8, 17.8);
  --bs-danger-tint-60: rgb(102, 35.6, 35.6);
  --bs-danger-tint-40: rgb(153, 53.4, 53.4);
  --bs-danger-tint-20: rgb(204, 71.2, 71.2);
  --bs-danger-tint-10: rgb(229.5, 80.1, 80.1);
  --bs-info: #64ABC6;
  --bs-info-rgb: 100, 171, 198;
  --bs-info-bg-subtle: rgb(224, 238.2, 243.6);
  --bs-info-border-subtle: rgb(193, 221.4, 232.2);
  --bs-link-color-rgb: var(--bs-info-rgb);
  --bs-info-hover-bg: rgb(80, 136.8, 158.4);
  --bs-info-hover-border: rgb(70, 119.7, 138.6);
  --bs-info-active-bg: rgb(80, 136.8, 158.4);
  --bs-info-active-border: rgb(70, 119.7, 138.6);
  --bs-info-shade-90: rgb(239.5, 246.6, 249.3);
  --bs-info-shade-80: rgb(224, 238.2, 243.6);
  --bs-info-shade-60: rgb(193, 221.4, 232.2);
  --bs-info-shade-40: rgb(162, 204.6, 220.8);
  --bs-info-shade-20: rgb(131, 187.8, 209.4);
  --bs-info-shade-10: rgb(115.5, 179.4, 203.7);
  --bs-info-tint-90: rgb(10, 17.1, 19.8);
  --bs-info-tint-80: rgb(20, 34.2, 39.6);
  --bs-info-tint-60: rgb(40, 68.4, 79.2);
  --bs-info-tint-40: rgb(60, 102.6, 118.8);
  --bs-info-tint-20: rgb(80, 136.8, 158.4);
  --bs-info-tint-10: rgb(90, 153.9, 178.2);
  --bs-light: #dee2e6;
  --bs-light-rgb: 222, 226, 230;
  --bs-light-bg-subtle: rgb(248.4, 249.2, 250);
  --bs-light-border-subtle: rgb(241.8, 243.4, 245);
  --bs-link-color-rgb: var(--bs-light-rgb);
  --bs-light-hover-bg: rgb(177.6, 180.8, 184);
  --bs-light-hover-border: rgb(155.4, 158.2, 161);
  --bs-light-active-bg: rgb(177.6, 180.8, 184);
  --bs-light-active-border: rgb(155.4, 158.2, 161);
  --bs-light-shade-90: rgb(251.7, 252.1, 252.5);
  --bs-light-shade-80: rgb(248.4, 249.2, 250);
  --bs-light-shade-60: rgb(241.8, 243.4, 245);
  --bs-light-shade-40: rgb(235.2, 237.6, 240);
  --bs-light-shade-20: rgb(228.6, 231.8, 235);
  --bs-light-shade-10: rgb(225.3, 228.9, 232.5);
  --bs-light-tint-90: rgb(22.2, 22.6, 23);
  --bs-light-tint-80: rgb(44.4, 45.2, 46);
  --bs-light-tint-60: rgb(88.8, 90.4, 92);
  --bs-light-tint-40: rgb(133.2, 135.6, 138);
  --bs-light-tint-20: rgb(177.6, 180.8, 184);
  --bs-light-tint-10: rgb(199.8, 203.4, 207);
  --bs-dark: #22292E;
  --bs-dark-rgb: 34, 41, 46;
  --bs-dark-bg-subtle: rgb(210.8, 212.2, 213.2);
  --bs-dark-border-subtle: rgb(166.6, 169.4, 171.4);
  --bs-link-color-rgb: var(--bs-dark-rgb);
  --bs-dark-hover-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-hover-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-active-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-active-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-shade-90: rgb(232.9, 233.6, 234.1);
  --bs-dark-shade-80: rgb(210.8, 212.2, 213.2);
  --bs-dark-shade-60: rgb(166.6, 169.4, 171.4);
  --bs-dark-shade-40: rgb(122.4, 126.6, 129.6);
  --bs-dark-shade-20: rgb(78.2, 83.8, 87.8);
  --bs-dark-shade-10: rgb(56.1, 62.4, 66.9);
  --bs-dark-tint-90: rgb(3.4, 4.1, 4.6);
  --bs-dark-tint-80: rgb(6.8, 8.2, 9.2);
  --bs-dark-tint-60: rgb(13.6, 16.4, 18.4);
  --bs-dark-tint-40: rgb(20.4, 24.6, 27.6);
  --bs-dark-tint-20: rgb(27.2, 32.8, 36.8);
  --bs-dark-tint-10: rgb(30.6, 36.9, 41.4);
  --bs-gray: #A3A3A3;
  --bs-gray-rgb: 163, 163, 163;
  --bs-gray-bg-subtle: rgb(236.6, 236.6, 236.6);
  --bs-gray-border-subtle: rgb(218.2, 218.2, 218.2);
  --bs-link-color-rgb: var(--bs-gray-rgb);
  --bs-gray-hover-bg: rgb(130.4, 130.4, 130.4);
  --bs-gray-hover-border: rgb(114.1, 114.1, 114.1);
  --bs-gray-active-bg: rgb(130.4, 130.4, 130.4);
  --bs-gray-active-border: rgb(114.1, 114.1, 114.1);
  --bs-gray-shade-90: rgb(245.8, 245.8, 245.8);
  --bs-gray-shade-80: rgb(236.6, 236.6, 236.6);
  --bs-gray-shade-60: rgb(218.2, 218.2, 218.2);
  --bs-gray-shade-40: rgb(199.8, 199.8, 199.8);
  --bs-gray-shade-20: rgb(181.4, 181.4, 181.4);
  --bs-gray-shade-10: rgb(172.2, 172.2, 172.2);
  --bs-gray-tint-90: rgb(16.3, 16.3, 16.3);
  --bs-gray-tint-80: rgb(32.6, 32.6, 32.6);
  --bs-gray-tint-60: rgb(65.2, 65.2, 65.2);
  --bs-gray-tint-40: rgb(97.8, 97.8, 97.8);
  --bs-gray-tint-20: rgb(130.4, 130.4, 130.4);
  --bs-gray-tint-10: rgb(146.7, 146.7, 146.7);
  --bs-gray-dark: #001F4D;
  --bs-gray-dark-rgb: 0, 31, 77;
  --bs-gray-dark-bg-subtle: rgb(204, 210.2, 219.4);
  --bs-gray-dark-border-subtle: rgb(153, 165.4, 183.8);
  --bs-link-color-rgb: var(--bs-gray-dark-rgb);
  --bs-gray-dark-hover-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-hover-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-active-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-active-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-shade-90: rgb(229.5, 232.6, 237.2);
  --bs-gray-dark-shade-80: rgb(204, 210.2, 219.4);
  --bs-gray-dark-shade-60: rgb(153, 165.4, 183.8);
  --bs-gray-dark-shade-40: rgb(102, 120.6, 148.2);
  --bs-gray-dark-shade-20: rgb(51, 75.8, 112.6);
  --bs-gray-dark-shade-10: rgb(25.5, 53.4, 94.8);
  --bs-gray-dark-tint-90: rgb(0, 3.1, 7.7);
  --bs-gray-dark-tint-80: rgb(0, 6.2, 15.4);
  --bs-gray-dark-tint-60: rgb(0, 12.4, 30.8);
  --bs-gray-dark-tint-40: rgb(0, 18.6, 46.2);
  --bs-gray-dark-tint-20: rgb(0, 24.8, 61.6);
  --bs-gray-dark-tint-10: rgb(0, 27.9, 69.3);
  --bs-indigo: #009688;
  --bs-indigo-rgb: 0, 150, 136;
  --bs-indigo-bg-subtle: rgb(204, 234, 231.2);
  --bs-indigo-border-subtle: rgb(153, 213, 207.4);
  --bs-link-color-rgb: var(--bs-indigo-rgb);
  --bs-indigo-hover-bg: rgb(0, 120, 108.8);
  --bs-indigo-hover-border: rgb(0, 105, 95.2);
  --bs-indigo-active-bg: rgb(0, 120, 108.8);
  --bs-indigo-active-border: rgb(0, 105, 95.2);
  --bs-indigo-shade-90: rgb(229.5, 244.5, 243.1);
  --bs-indigo-shade-80: rgb(204, 234, 231.2);
  --bs-indigo-shade-60: rgb(153, 213, 207.4);
  --bs-indigo-shade-40: rgb(102, 192, 183.6);
  --bs-indigo-shade-20: rgb(51, 171, 159.8);
  --bs-indigo-shade-10: rgb(25.5, 160.5, 147.9);
  --bs-indigo-tint-90: rgb(0, 15, 13.6);
  --bs-indigo-tint-80: rgb(0, 30, 27.2);
  --bs-indigo-tint-60: rgb(0, 60, 54.4);
  --bs-indigo-tint-40: rgb(0, 90, 81.6);
  --bs-indigo-tint-20: rgb(0, 120, 108.8);
  --bs-indigo-tint-10: rgb(0, 135, 122.4);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-primary-text-emphasis: rgb(242.4, 165.6, 112.8);
  --bs-primary-bg-subtle: rgb(46.8, 21.2, 3.6);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-secondary-text-emphasis: rgb(162, 111.6, 246.6);
  --bs-secondary-bg-subtle: rgb(20, 3.2, 48.2);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-success-text-emphasis: rgb(132.6, 222, 184.2);
  --bs-success-bg-subtle: rgb(10.2, 40, 27.4);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-warning-text-emphasis: rgb(240.6, 168.6, 150.6);
  --bs-warning-bg-subtle: rgb(46.2, 22.2, 16.2);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-danger-text-emphasis: rgb(255, 155.4, 155.4);
  --bs-danger-bg-subtle: rgb(51, 17.8, 17.8);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-info-text-emphasis: rgb(162, 204.6, 220.8);
  --bs-info-bg-subtle: rgb(20, 34.2, 39.6);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-light-text-emphasis: rgb(235.2, 237.6, 240);
  --bs-light-bg-subtle: rgb(44.4, 45.2, 46);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-dark-text-emphasis: rgb(122.4, 126.6, 129.6);
  --bs-dark-bg-subtle: rgb(6.8, 8.2, 9.2);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-gray-text-emphasis: rgb(199.8, 199.8, 199.8);
  --bs-gray-bg-subtle: rgb(32.6, 32.6, 32.6);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-gray-dark-text-emphasis: rgb(102, 120.6, 148.2);
  --bs-gray-dark-bg-subtle: rgb(0, 6.2, 15.4);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-indigo-text-emphasis: rgb(102, 192, 183.6);
  --bs-indigo-bg-subtle: rgb(0, 30, 27.2);
}

[data-bs-theme-color=color-5] {
  --bs-primary: #E586B3;
  --bs-primary-rgb: 229, 134, 179;
  --bs-primary-bg-subtle: rgb(249.8, 230.8, 239.8);
  --bs-primary-border-subtle: rgb(244.6, 206.6, 224.6);
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-primary-hover-bg: rgb(183.2, 107.2, 143.2);
  --bs-primary-hover-border: rgb(160.3, 93.8, 125.3);
  --bs-primary-active-bg: rgb(183.2, 107.2, 143.2);
  --bs-primary-active-border: rgb(160.3, 93.8, 125.3);
  --bs-primary-shade-90: rgb(252.4, 242.9, 247.4);
  --bs-primary-shade-80: rgb(249.8, 230.8, 239.8);
  --bs-primary-shade-60: rgb(244.6, 206.6, 224.6);
  --bs-primary-shade-40: rgb(239.4, 182.4, 209.4);
  --bs-primary-shade-20: rgb(234.2, 158.2, 194.2);
  --bs-primary-shade-10: rgb(231.6, 146.1, 186.6);
  --bs-primary-tint-90: rgb(22.9, 13.4, 17.9);
  --bs-primary-tint-80: rgb(45.8, 26.8, 35.8);
  --bs-primary-tint-60: rgb(91.6, 53.6, 71.6);
  --bs-primary-tint-40: rgb(137.4, 80.4, 107.4);
  --bs-primary-tint-20: rgb(183.2, 107.2, 143.2);
  --bs-primary-tint-10: rgb(206.1, 120.6, 161.1);
  --bs-secondary: #25C799;
  --bs-secondary-rgb: 37, 199, 153;
  --bs-secondary-bg-subtle: rgb(211.4, 243.8, 234.6);
  --bs-secondary-border-subtle: rgb(167.8, 232.6, 214.2);
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-secondary-hover-bg: rgb(29.6, 159.2, 122.4);
  --bs-secondary-hover-border: rgb(25.9, 139.3, 107.1);
  --bs-secondary-active-bg: rgb(29.6, 159.2, 122.4);
  --bs-secondary-active-border: rgb(25.9, 139.3, 107.1);
  --bs-secondary-shade-90: rgb(233.2, 249.4, 244.8);
  --bs-secondary-shade-80: rgb(211.4, 243.8, 234.6);
  --bs-secondary-shade-60: rgb(167.8, 232.6, 214.2);
  --bs-secondary-shade-40: rgb(124.2, 221.4, 193.8);
  --bs-secondary-shade-20: rgb(80.6, 210.2, 173.4);
  --bs-secondary-shade-10: rgb(58.8, 204.6, 163.2);
  --bs-secondary-tint-90: rgb(3.7, 19.9, 15.3);
  --bs-secondary-tint-80: rgb(7.4, 39.8, 30.6);
  --bs-secondary-tint-60: rgb(14.8, 79.6, 61.2);
  --bs-secondary-tint-40: rgb(22.2, 119.4, 91.8);
  --bs-secondary-tint-20: rgb(29.6, 159.2, 122.4);
  --bs-secondary-tint-10: rgb(33.3, 179.1, 137.7);
  --bs-success: #23A16C;
  --bs-success-rgb: 35, 161, 108;
  --bs-success-bg-subtle: rgb(211, 236.2, 225.6);
  --bs-success-border-subtle: rgb(167, 217.4, 196.2);
  --bs-link-color-rgb: var(--bs-success-rgb);
  --bs-success-hover-bg: rgb(28, 128.8, 86.4);
  --bs-success-hover-border: rgb(24.5, 112.7, 75.6);
  --bs-success-active-bg: rgb(28, 128.8, 86.4);
  --bs-success-active-border: rgb(24.5, 112.7, 75.6);
  --bs-success-shade-90: rgb(233, 245.6, 240.3);
  --bs-success-shade-80: rgb(211, 236.2, 225.6);
  --bs-success-shade-60: rgb(167, 217.4, 196.2);
  --bs-success-shade-40: rgb(123, 198.6, 166.8);
  --bs-success-shade-20: rgb(79, 179.8, 137.4);
  --bs-success-shade-10: rgb(57, 170.4, 122.7);
  --bs-success-tint-90: rgb(3.5, 16.1, 10.8);
  --bs-success-tint-80: rgb(7, 32.2, 21.6);
  --bs-success-tint-60: rgb(14, 64.4, 43.2);
  --bs-success-tint-40: rgb(21, 96.6, 64.8);
  --bs-success-tint-20: rgb(28, 128.8, 86.4);
  --bs-success-tint-10: rgb(31.5, 144.9, 97.2);
  --bs-warning: #E97B20;
  --bs-warning-rgb: 233, 123, 32;
  --bs-warning-bg-subtle: rgb(250.6, 228.6, 210.4);
  --bs-warning-border-subtle: rgb(246.2, 202.2, 165.8);
  --bs-link-color-rgb: var(--bs-warning-rgb);
  --bs-warning-hover-bg: rgb(186.4, 98.4, 25.6);
  --bs-warning-hover-border: rgb(163.1, 86.1, 22.4);
  --bs-warning-active-bg: rgb(186.4, 98.4, 25.6);
  --bs-warning-active-border: rgb(163.1, 86.1, 22.4);
  --bs-warning-shade-90: rgb(252.8, 241.8, 232.7);
  --bs-warning-shade-80: rgb(250.6, 228.6, 210.4);
  --bs-warning-shade-60: rgb(246.2, 202.2, 165.8);
  --bs-warning-shade-40: rgb(241.8, 175.8, 121.2);
  --bs-warning-shade-20: rgb(237.4, 149.4, 76.6);
  --bs-warning-shade-10: rgb(235.2, 136.2, 54.3);
  --bs-warning-tint-90: rgb(23.3, 12.3, 3.2);
  --bs-warning-tint-80: rgb(46.6, 24.6, 6.4);
  --bs-warning-tint-60: rgb(93.2, 49.2, 12.8);
  --bs-warning-tint-40: rgb(139.8, 73.8, 19.2);
  --bs-warning-tint-20: rgb(186.4, 98.4, 25.6);
  --bs-warning-tint-10: rgb(209.7, 110.7, 28.8);
  --bs-danger: #D64141;
  --bs-danger-rgb: 214, 65, 65;
  --bs-danger-bg-subtle: rgb(246.8, 217, 217);
  --bs-danger-border-subtle: rgb(238.6, 179, 179);
  --bs-link-color-rgb: var(--bs-danger-rgb);
  --bs-danger-hover-bg: rgb(171.2, 52, 52);
  --bs-danger-hover-border: rgb(149.8, 45.5, 45.5);
  --bs-danger-active-bg: rgb(171.2, 52, 52);
  --bs-danger-active-border: rgb(149.8, 45.5, 45.5);
  --bs-danger-shade-90: rgb(250.9, 236, 236);
  --bs-danger-shade-80: rgb(246.8, 217, 217);
  --bs-danger-shade-60: rgb(238.6, 179, 179);
  --bs-danger-shade-40: rgb(230.4, 141, 141);
  --bs-danger-shade-20: rgb(222.2, 103, 103);
  --bs-danger-shade-10: rgb(218.1, 84, 84);
  --bs-danger-tint-90: rgb(21.4, 6.5, 6.5);
  --bs-danger-tint-80: rgb(42.8, 13, 13);
  --bs-danger-tint-60: rgb(85.6, 26, 26);
  --bs-danger-tint-40: rgb(128.4, 39, 39);
  --bs-danger-tint-20: rgb(171.2, 52, 52);
  --bs-danger-tint-10: rgb(192.6, 58.5, 58.5);
  --bs-info: #69CBF0;
  --bs-info-rgb: 105, 203, 240;
  --bs-info-bg-subtle: rgb(225, 244.6, 252);
  --bs-info-border-subtle: rgb(195, 234.2, 249);
  --bs-link-color-rgb: var(--bs-info-rgb);
  --bs-info-hover-bg: rgb(84, 162.4, 192);
  --bs-info-hover-border: rgb(73.5, 142.1, 168);
  --bs-info-active-bg: rgb(84, 162.4, 192);
  --bs-info-active-border: rgb(73.5, 142.1, 168);
  --bs-info-shade-90: rgb(240, 249.8, 253.5);
  --bs-info-shade-80: rgb(225, 244.6, 252);
  --bs-info-shade-60: rgb(195, 234.2, 249);
  --bs-info-shade-40: rgb(165, 223.8, 246);
  --bs-info-shade-20: rgb(135, 213.4, 243);
  --bs-info-shade-10: rgb(120, 208.2, 241.5);
  --bs-info-tint-90: rgb(10.5, 20.3, 24);
  --bs-info-tint-80: rgb(21, 40.6, 48);
  --bs-info-tint-60: rgb(42, 81.2, 96);
  --bs-info-tint-40: rgb(63, 121.8, 144);
  --bs-info-tint-20: rgb(84, 162.4, 192);
  --bs-info-tint-10: rgb(94.5, 182.7, 216);
  --bs-light: #dee2e6;
  --bs-light-rgb: 222, 226, 230;
  --bs-light-bg-subtle: rgb(248.4, 249.2, 250);
  --bs-light-border-subtle: rgb(241.8, 243.4, 245);
  --bs-link-color-rgb: var(--bs-light-rgb);
  --bs-light-hover-bg: rgb(177.6, 180.8, 184);
  --bs-light-hover-border: rgb(155.4, 158.2, 161);
  --bs-light-active-bg: rgb(177.6, 180.8, 184);
  --bs-light-active-border: rgb(155.4, 158.2, 161);
  --bs-light-shade-90: rgb(251.7, 252.1, 252.5);
  --bs-light-shade-80: rgb(248.4, 249.2, 250);
  --bs-light-shade-60: rgb(241.8, 243.4, 245);
  --bs-light-shade-40: rgb(235.2, 237.6, 240);
  --bs-light-shade-20: rgb(228.6, 231.8, 235);
  --bs-light-shade-10: rgb(225.3, 228.9, 232.5);
  --bs-light-tint-90: rgb(22.2, 22.6, 23);
  --bs-light-tint-80: rgb(44.4, 45.2, 46);
  --bs-light-tint-60: rgb(88.8, 90.4, 92);
  --bs-light-tint-40: rgb(133.2, 135.6, 138);
  --bs-light-tint-20: rgb(177.6, 180.8, 184);
  --bs-light-tint-10: rgb(199.8, 203.4, 207);
  --bs-dark: #22292E;
  --bs-dark-rgb: 34, 41, 46;
  --bs-dark-bg-subtle: rgb(210.8, 212.2, 213.2);
  --bs-dark-border-subtle: rgb(166.6, 169.4, 171.4);
  --bs-link-color-rgb: var(--bs-dark-rgb);
  --bs-dark-hover-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-hover-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-active-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-active-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-shade-90: rgb(232.9, 233.6, 234.1);
  --bs-dark-shade-80: rgb(210.8, 212.2, 213.2);
  --bs-dark-shade-60: rgb(166.6, 169.4, 171.4);
  --bs-dark-shade-40: rgb(122.4, 126.6, 129.6);
  --bs-dark-shade-20: rgb(78.2, 83.8, 87.8);
  --bs-dark-shade-10: rgb(56.1, 62.4, 66.9);
  --bs-dark-tint-90: rgb(3.4, 4.1, 4.6);
  --bs-dark-tint-80: rgb(6.8, 8.2, 9.2);
  --bs-dark-tint-60: rgb(13.6, 16.4, 18.4);
  --bs-dark-tint-40: rgb(20.4, 24.6, 27.6);
  --bs-dark-tint-20: rgb(27.2, 32.8, 36.8);
  --bs-dark-tint-10: rgb(30.6, 36.9, 41.4);
  --bs-gray: #949494;
  --bs-gray-rgb: 148, 148, 148;
  --bs-gray-bg-subtle: rgb(233.6, 233.6, 233.6);
  --bs-gray-border-subtle: rgb(212.2, 212.2, 212.2);
  --bs-link-color-rgb: var(--bs-gray-rgb);
  --bs-gray-hover-bg: rgb(118.4, 118.4, 118.4);
  --bs-gray-hover-border: rgb(103.6, 103.6, 103.6);
  --bs-gray-active-bg: rgb(118.4, 118.4, 118.4);
  --bs-gray-active-border: rgb(103.6, 103.6, 103.6);
  --bs-gray-shade-90: rgb(244.3, 244.3, 244.3);
  --bs-gray-shade-80: rgb(233.6, 233.6, 233.6);
  --bs-gray-shade-60: rgb(212.2, 212.2, 212.2);
  --bs-gray-shade-40: rgb(190.8, 190.8, 190.8);
  --bs-gray-shade-20: rgb(169.4, 169.4, 169.4);
  --bs-gray-shade-10: rgb(158.7, 158.7, 158.7);
  --bs-gray-tint-90: rgb(14.8, 14.8, 14.8);
  --bs-gray-tint-80: rgb(29.6, 29.6, 29.6);
  --bs-gray-tint-60: rgb(59.2, 59.2, 59.2);
  --bs-gray-tint-40: rgb(88.8, 88.8, 88.8);
  --bs-gray-tint-20: rgb(118.4, 118.4, 118.4);
  --bs-gray-tint-10: rgb(133.2, 133.2, 133.2);
  --bs-gray-dark: #001F4D;
  --bs-gray-dark-rgb: 0, 31, 77;
  --bs-gray-dark-bg-subtle: rgb(204, 210.2, 219.4);
  --bs-gray-dark-border-subtle: rgb(153, 165.4, 183.8);
  --bs-link-color-rgb: var(--bs-gray-dark-rgb);
  --bs-gray-dark-hover-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-hover-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-active-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-active-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-shade-90: rgb(229.5, 232.6, 237.2);
  --bs-gray-dark-shade-80: rgb(204, 210.2, 219.4);
  --bs-gray-dark-shade-60: rgb(153, 165.4, 183.8);
  --bs-gray-dark-shade-40: rgb(102, 120.6, 148.2);
  --bs-gray-dark-shade-20: rgb(51, 75.8, 112.6);
  --bs-gray-dark-shade-10: rgb(25.5, 53.4, 94.8);
  --bs-gray-dark-tint-90: rgb(0, 3.1, 7.7);
  --bs-gray-dark-tint-80: rgb(0, 6.2, 15.4);
  --bs-gray-dark-tint-60: rgb(0, 12.4, 30.8);
  --bs-gray-dark-tint-40: rgb(0, 18.6, 46.2);
  --bs-gray-dark-tint-20: rgb(0, 24.8, 61.6);
  --bs-gray-dark-tint-10: rgb(0, 27.9, 69.3);
  --bs-indigo: #009688;
  --bs-indigo-rgb: 0, 150, 136;
  --bs-indigo-bg-subtle: rgb(204, 234, 231.2);
  --bs-indigo-border-subtle: rgb(153, 213, 207.4);
  --bs-link-color-rgb: var(--bs-indigo-rgb);
  --bs-indigo-hover-bg: rgb(0, 120, 108.8);
  --bs-indigo-hover-border: rgb(0, 105, 95.2);
  --bs-indigo-active-bg: rgb(0, 120, 108.8);
  --bs-indigo-active-border: rgb(0, 105, 95.2);
  --bs-indigo-shade-90: rgb(229.5, 244.5, 243.1);
  --bs-indigo-shade-80: rgb(204, 234, 231.2);
  --bs-indigo-shade-60: rgb(153, 213, 207.4);
  --bs-indigo-shade-40: rgb(102, 192, 183.6);
  --bs-indigo-shade-20: rgb(51, 171, 159.8);
  --bs-indigo-shade-10: rgb(25.5, 160.5, 147.9);
  --bs-indigo-tint-90: rgb(0, 15, 13.6);
  --bs-indigo-tint-80: rgb(0, 30, 27.2);
  --bs-indigo-tint-60: rgb(0, 60, 54.4);
  --bs-indigo-tint-40: rgb(0, 90, 81.6);
  --bs-indigo-tint-20: rgb(0, 120, 108.8);
  --bs-indigo-tint-10: rgb(0, 135, 122.4);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-primary-text-emphasis: rgb(239.4, 182.4, 209.4);
  --bs-primary-bg-subtle: rgb(45.8, 26.8, 35.8);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-secondary-text-emphasis: rgb(124.2, 221.4, 193.8);
  --bs-secondary-bg-subtle: rgb(7.4, 39.8, 30.6);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-success-text-emphasis: rgb(123, 198.6, 166.8);
  --bs-success-bg-subtle: rgb(7, 32.2, 21.6);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-warning-text-emphasis: rgb(241.8, 175.8, 121.2);
  --bs-warning-bg-subtle: rgb(46.6, 24.6, 6.4);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-danger-text-emphasis: rgb(230.4, 141, 141);
  --bs-danger-bg-subtle: rgb(42.8, 13, 13);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-info-text-emphasis: rgb(165, 223.8, 246);
  --bs-info-bg-subtle: rgb(21, 40.6, 48);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-light-text-emphasis: rgb(235.2, 237.6, 240);
  --bs-light-bg-subtle: rgb(44.4, 45.2, 46);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-dark-text-emphasis: rgb(122.4, 126.6, 129.6);
  --bs-dark-bg-subtle: rgb(6.8, 8.2, 9.2);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-gray-text-emphasis: rgb(190.8, 190.8, 190.8);
  --bs-gray-bg-subtle: rgb(29.6, 29.6, 29.6);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-gray-dark-text-emphasis: rgb(102, 120.6, 148.2);
  --bs-gray-dark-bg-subtle: rgb(0, 6.2, 15.4);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-indigo-text-emphasis: rgb(102, 192, 183.6);
  --bs-indigo-bg-subtle: rgb(0, 30, 27.2);
}